import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { getOutcomeContent, OutcomeContent, OutcomeStatus } from './enrollment-outcome.content';
import { ApiService } from 'src/app/services/api.service';
import { CustomerService } from 'src/app/services/customer.service';
import { Subject, takeUntil } from 'rxjs';
import { Customer } from '../../shared/models/customer';



@Component({
  selector: 'app-enrollment-outcome',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './enrollment-outcome.component.html',
  styleUrl: './enrollment-outcome.component.scss'
})

export class EnrollmentOutcomeComponent implements OnInit, OnDestroy {
  content:OutcomeContent;
  protected _onDestroy = new Subject<void>();
  customer: Customer = undefined;

  constructor(private route: ActivatedRoute,
        private _customerService: CustomerService,
         private router: Router,
         private _apiService: ApiService,
  ) {
        this._customerService.customer.pipe(takeUntil(this._onDestroy)).subscribe(cust => {
          this.customer = cust;
        });
  }
 
  ngOnInit(): void {
    const status = this.route.snapshot.paramMap.get('status');
    const outcome:OutcomeStatus = OutcomeStatus[status];
    this.content = getOutcomeContent(outcome);

    if(outcome == OutcomeStatus.success && this.customer != undefined){
      this._apiService.clearAWSCart(this.customer.business_partner_id);
    }
  }

  
  gotoLink(link:string) {
    this.router.navigate([link]);
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
  }
}