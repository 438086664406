import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ErrorModalService {
  private _errorModal = new Subject<string>();

  get $errorMessage(): Observable<string> {
    return this._errorModal.asObservable();
  }

  public triggerErrorModal(errorMessage: string = undefined): void {
    this._errorModal.next(errorMessage);
  }
}
