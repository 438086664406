import { Component, Input, OnInit } from '@angular/core';
import { EdsButtonComponent } from '@electronds/ng-components-duke';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-disclaimer',
  standalone: true,
  imports: [EdsButtonComponent, CommonModule],
  templateUrl: './disclaimer.component.html',
  styleUrl: './disclaimer.component.scss'
})
export class DisclaimerComponent implements OnInit {
  @Input() planId: string;

  disclaimer: string;
  extendedDisclaimer: string;

  constructor() {}

  ngOnInit(): void {
    // Show specific plan disclaimer unless there's not one, then show the basic home disclaimer
    this.disclaimer = '*Exclusions and limitations apply. See terms and conditions details at applicable product page. These products or services are administered by Duke Energy One, Inc. located at 526 S. Church Street, Charlotte, NC 28202 and provided by TWG Home Warranty Services, Inc. (in FL, provided by ServicePlan of Florida, Inc.) located at 175 W Jackson Blvd., Chicago, IL 60604.'
    this.extendedDisclaimer = ''
  }
}
