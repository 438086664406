import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Subject, take, takeUntil } from 'rxjs';
import { AddressResult, AddressSearchComponent } from 'src/app/address-search/address-search.component';
import { ModalMessageComponent } from 'src/app/components/modal-message/modal-message.component';
import { ApiService } from 'src/app/services/api.service';
import { CustomerService } from 'src/app/services/customer.service';
import { ToastService } from 'src/app/services/toast.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { AddressValidationRequest } from './location-models';
import { LoaderService } from 'src/app/services/loader.service';
import { CacheService } from 'src/app/services/cache.service';
import { CartService } from 'src/app/services/shopping-cart/cart.service';
import { Customer, HouseType, FamilyType, ResidenceClasification } from 'src/app/shared/models/customer';

@Component({
  selector: 'app-location-check',
  standalone: true,
  imports: [SharedModule, AddressSearchComponent, ModalMessageComponent],
  templateUrl: './location-check.component.html',
  styleUrl: './location-check.component.scss'
})
export class LocationCheckComponent implements OnInit, OnDestroy {
 @ViewChild('modalTemplate') locationModal: any;

  eligForm: FormGroup;
  public residentialControl = new FormControl('residential');
  public showAparmentNumberModal = false;
  clasification:ResidenceClasification = undefined;
  propertyType:FamilyType = undefined;
  houseType:HouseType = undefined;
  userAddress: AddressResult = undefined;
  protected _onDestroy = new Subject<void>();
  customer:Customer = undefined;
  
  public get hasNoPlans(): boolean {
    return this._cart.isEmpty();
  }

  constructor(
    private _router: Router,
    private _customerService: CustomerService,
    private route: ActivatedRoute,
    private _apiService: ApiService,
    private changeDetectorRef: ChangeDetectorRef,
    private _api: ApiService,
    private _loaderService: LoaderService,
    private _cache: CacheService,
    private _cart: CartService,
    private _toastService: ToastService) {
      this.buildForm();
  }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(params => {
      this.extractPlansFromQueryParams(params);
    });

    this._customerService.customer.pipe(takeUntil(this._onDestroy)).subscribe(cust => {
          this.customer = cust;
    });

    this.changeDetectorRef.detectChanges();
  }

  buildForm(): void {
    this.eligForm = new FormGroup({
      residential: new FormControl(''),
      propertyType: new FormControl(''),
      ownOrRent: new FormControl(''),
    });
  }

// A helper function to extract one or more plans from the query parameters in the url
extractPlansFromQueryParams(params: ParamMap): void {
  const plansParam = params.get('plans');
  if (plansParam) {
    const plans = plansParam.split(',');
    plans.forEach((plan) => {
      this.onProductCodeFound(plan.trim());
    });
  }
}




  answerForAddress(address: AddressResult): void {
    this.userAddress = address;
  }

  answerForResidentialQuestion(response: string): void {
    if(response == 'Residential') {
      this.clasification = ResidenceClasification.RESIDENTIAL;
    } else if(response == 'Non-Residential (Business)') {
      this.clasification = ResidenceClasification.RESIDENTIAL;
    }
  }

  onProductCodeFound(plan: string){
    this._cart.add(plan);
  }

  answerForpropertyTypeQuestion(response: string): void {
    if(response == 'Single Family') {
      this.propertyType = FamilyType.SINGLE_FAMILY;
    } else if(response == 'Multi-family') {
      this.propertyType = FamilyType.MULTI_FAMILY;
    } else if(response == 'Mobile Home') {
      this.propertyType = FamilyType.MOBILE;
    }

  }

  answerForOwnOrRentQuestion(response: string): void {
    if(response == 'Own') {
      this.houseType = HouseType.OWN;
    } else if(response == 'Rent') {
      this.houseType = HouseType.RENT;
    }
  }

  guardForValidAddress(): boolean {
    return (!this.userAddress || !this.userAddress?.zipCode || !this.userAddress?.city || !this.userAddress?.street || !this.userAddress?.state) ? true : false;
  }

  guardForAllAnswersCompleted(): boolean {
    return (this.clasification === undefined || this.propertyType === undefined || this.houseType === undefined) ? true : false;
  }

  public continueActionClicked(): void {
    if (this.guardForValidAddress()) {

      this._toastService.triggerErrorToast('Please enter a valid address.');
    } else if (this.guardForAllAnswersCompleted()) {

      this._toastService.triggerErrorToast('Please answer the questions above.')
    } else {
        this.performValidServiceAddressheck()
    }
  }

  public valueOrNull(value:string){
      if(value == undefined){
        return undefined;
      }

      if (value.length == 0) {
          return undefined
      }

      return value;
  }

  // Checks to see if the input address is a valid address for DEONE use.
  public performValidServiceAddressheck() {
    const request:AddressValidationRequest = {
                                                street_address:this.userAddress.street,
                                                house_number:  '',
                                                city:          this.userAddress.city,
                                                state:         this.userAddress.state,
                                                zip_code:      this.userAddress.zipCode,
                                                apartment:     this.userAddress.apartment
                                              }
  this._loaderService.requestShowLoader();
  this._apiService.validateServiceAddress(request).pipe(take(1)).subscribe({
          next: response => {
            this._loaderService.requestHideLoader();

            if(response.is_valid && !response.is_native){
             // a valid non-native address is a good de-one address
             const validatedAddress:AddressResult = {
                house_number: this.valueOrNull(response.address.house_number) ?? this.userAddress.house_number, 
                street: this.valueOrNull(response.address.street_address) ?? request.street_address,
                apartment: this.valueOrNull(response.address.apartment) ?? request.apartment,
                city: request.city,
                state:request.state,
                zipCode:request.zip_code
              }
              this._customerService.updateEligibility(validatedAddress, this.clasification, this.propertyType, this.houseType);
              const showApptPrompt:boolean = (response.apartment_number_prompt && this.userAddress.apartment.length == 0);
              this.showAparmentNumberModal = showApptPrompt;
              if(!showApptPrompt) {
               /// apartment number not required just proceed to home
               this.getEligiblePlans();
              }
            } else if (!response.is_valid) {
              // address is all together invalid show not-eligible screen
               this._router.navigate(['unsupported'], { queryParams: { reason: 'not-eligible'} });
            } else if (response.is_native) {
              // address is valid but not de-one material, show the duke territory error
               this._router.navigate(['unsupported'], { queryParams: { reason: 'duke-energy'} });
            } else {
              // should never hit here but catch all for if it falls through
              this._router.navigate(['unsupported'], { queryParams: { reason: 'duke-energy'} });
            }
          },
          error: err => {
            this._loaderService.requestHideLoader();
            this._toastService.triggerErrorToast('Unknown Error Validating addresss');
            console.error(err);
          }
        });
  }

  public previousActionClicked() {
    this._router.navigate(['home']);
  }

  public edit(): void {
    this.showAparmentNumberModal = false;
  }

  public async performValidNavigation(): Promise<void> {
    this.showAparmentNumberModal = false;
    // capture the appartment number if the user entered one. Note: it could be empty as they are allowed to skip that step even if they should
    if(this.userAddress.apartment !== undefined && this.userAddress.apartment.length > 0) {
      this._customerService.updateAppartmentNumber(parseInt(this.userAddress.apartment));

    }

    this.showAparmentNumberModal = false;
    if(!this.hasNoPlans){
       this._router.navigate(['shopping-cart']);
    }
   else {
    this._router.navigate(['home']);
   }
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
  }

  async getEligiblePlans() {
    this._loaderService.requestShowLoader();
    try {

      const response = await this._cache.getEligiblePlans(this.customer, this._api, this._cart);
      if (!response) {
        this._loaderService.requestHideLoader();
        this._toastService.triggerErrorToast('Unknown Error Getting plans');
        return;
      }
      this._loaderService.requestHideLoader();
      if (response.products.length == 0) {
        this._router.navigate(['unsupported'], { queryParams: { reason: 'not-eligible' } });
      } else {
        this.performValidNavigation();
      }

    } catch (error) {
        this._loaderService.requestHideLoader();
        this._toastService.triggerErrorToast('Unknown Error Getting plans');
    }
  }





    // TODO: CODE NOT FOR PROD
  // AGAIN: NOT FOR PROD
  // ONLY FOR TESTING!!!!!
  populateAddressForTesting(): void {
    this.userAddress = { apartment: '', city: 'Woodstock', house_number: '701', state: 'GA', street: '701 Kirkwood Circle', zipCode: '30189' }
    this.propertyType = FamilyType.SINGLE_FAMILY;
    this.clasification = ResidenceClasification.RESIDENTIAL;
    this.houseType = HouseType.OWN;
    this.continueActionClicked();
  }
}