<div class="pb-48 px-24">
    <div class="flex flex-col items-center px-48 pt-48">
        <div class="flex flex-col items-center max-w-md text-center">
            <h2 class="text-3xl md:text-4xl pb-20 text-dukeDarkBlue Roboto">We Recommend the Following Plans</h2>
        </div>
    </div>

<div class="flex flex-wrap gap-y-12 justify-around mt-20">
    <div *ngFor="let plan of tiles" class="lg:w-[45%] mb-8 min-h-[200px] relative">
<div *ngIf="this.hasOverlap(plan.productCodes, this.plans)" 
     class="absolute bottom-4 left-2 bg-blue-600 bg-opacity-90 text-black px-3 py-1 z-20 rounded-md transform font-semibold text-sm flex items-center">
  <span class="mr-4">&#10003;</span>
  Already Selected
</div>
  
        <app-simple-card 
            (click)="clickOnPlan(plan)" 
            class="hover:cursor-pointer w-full h-full pb-12" 
            [cardTitle]="plan.name" 
            [description]="plan.description" 
            [cardIcon]="plan.image">
        </app-simple-card>
    </div>
</div>
</div>
