
export enum SourceType {
  DEONE = 'de-one',
  MyAccount = 'my-account'
}

export interface SAPCartTransferRequest {
  address_number:   string;
  cart:             string[];
}

export interface SAPCartItemResponse {
  cartID: string;
  friendlyErrorMessage: string;
  message: string;
  product: string;
  responseCode: number;
  success: boolean;
}
export interface CartTrasnferRejectedPayload {
  code:string;
  message:string;
}
export interface CartTransferResponse {
  rejected: CartTrasnferRejectedPayload[];
  success: string[];
}

// SAP model for modifying cart
export interface SAPCartItemRequest {
  bpId: string;
  cartId?: string; // not required for initial transfer
  code?: string; // product code when adding
  premiseId: string; // address number
  quantity?: number; //default is 1
  entryNumber?: number; // index of item in the cart

}

export interface SAPCartResponse {
  product:string;
  success:boolean;
  cartID: string;
  message:string;
}