import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { DisclaimerComponent } from '../../disclaimer-component/disclaimer.component';
import { Plan } from '../../Models/PlansContent';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject, Subscription, fromEvent, takeUntil } from 'rxjs';
import { ToastService } from '../../services/toast.service';
import { CustomerService } from 'src/app/services/customer.service';
import { LoaderService } from '../../services/loader.service';
import { Customer } from 'src/app/shared/models/customer';
import { CacheService } from 'src/app/services/cache.service';
import { CartService } from 'src/app/services/shopping-cart/cart.service';
import { ErrorModalService } from 'src/app/services/error-modal.service';

@Component({
  selector: 'app-tier',
  standalone: true,
  imports: [DisclaimerComponent, CommonModule, SharedModule],
  templateUrl: './tier.component.html',
  styleUrl: './tier.component.scss',
})
export class WhichTierComponent implements OnInit, OnDestroy {
  plan: Plan;
  resizeObservable$: Observable<Event>
  resizeSubscription$: Subscription;
  public currentCategoryID: string = '';
  public isNotMobileLayout = false;
  public selectedItem = -1;
  public preSelectedItemId = ''
  public customer: Customer;
  public buttonTitle = 'Add Plan';
  protected _onDestroy = new Subject<void>();
  private _subs: Subscription[] = [];
  public addedPlans: string[] = [];


  constructor(
    private _router: Router,
    private route: ActivatedRoute,
    private _toastService: ToastService,
    private _errorModalService: ErrorModalService,
    private location: Location,
    private _cart: CartService,
    private _loaderService: LoaderService,
    private _cacheService: CacheService,
  ) { }


  ngOnInit(): void {
    // TODO: unsubscribe safely
    this._subs.push(
      this._cart.items.pipe(takeUntil(this._onDestroy)).subscribe(plans => {
        this.addedPlans = plans;
      })
    )
    this._cacheService.rawPlans ? this.fetchCachedPlanDetails() : this._router.navigate(['home']);
  }

  findIfPlanIsAlreadyPreselected(): boolean {
    this.selectedItem = this.plan.content.tiers.findIndex(tier => this._cart.hasPlan(tier.id));
    return this.selectedItem >= 0;
  }

  findOutPreselectedPlanId(): string | undefined {
    return this.plan.content.tiers.find(tier => this._cart.hasPlan(tier.id))?.id
  }


  handleMainButtonAction():void {
    const planID:string = this.plan.content.tiers[this.selectedItem].id;
    if (planID === this.preSelectedItemId) {
      this._router.navigate(['shopping-cart']);
    } else if (this.preSelectedItemId) {
      this.updatePlan()
    } else {
      this.addPlan()
    }
  }

  fetchCachedPlanDetails(): void {
    const categoryID = this.route.snapshot.queryParams['plan'] as string;
    this.plan = this._cacheService.getProductDetail(categoryID);
    this.preSelectedItemId = this.findOutPreselectedPlanId();
    this.formatPlan();
    if (this.findIfPlanIsAlreadyPreselected()) {
      this.buttonTitle = 'Return to Cart';
    }
  }


  formatPlan(): void {
    this.isNotMobileLayout = window.innerWidth >= 768;
    this.resizeObservable$ = fromEvent(window, 'resize')
    this._subs.push(this.resizeSubscription$ = this.resizeObservable$.subscribe(_ => {
      this.isNotMobileLayout = window.innerWidth >= 768;
    }));
    this._loaderService.requestHideLoader()
  }

  clickOnActionButton(index: number): void {
    this.selectedItem = this.selectedItem == index ? 1 : index;
    const planID:string = this.plan.content.tiers[this.selectedItem].id;
    if (planID === this.preSelectedItemId) {
      this.buttonTitle = 'Return to Cart'
    } else if (this.preSelectedItemId) {
      this.buttonTitle = 'Update Plan'
    } else {
      this.buttonTitle = 'Add Plan'
    }
  }


  onClickTCLink() {
    window.open(this.plan.content.planTile.tcLink, '_blank');
  }

  async addPlan() {

    // TODO: maybe also check for a selected plan?
    if (this.selectedItem == -1) {
      this._toastService.triggerErrorToast('Please select a plan to move forward');
    } else {
      this._loaderService.requestShowLoader();
      const planID: string = this.plan.content.tiers[this.selectedItem].id;
      let success = false;
      try {
        success = await this._cart.add(planID);
        if (success) {
          this._toastService.triggerSuccessToast('Plan successfully added');
          this._router.navigate(['shopping-cart']);
        } else { // TODO: shouldnt hit here?
          this._errorModalService.triggerErrorModal('An unknown  error has occured. Please try again.');
        }
      } catch (error) {
        const errorMessage = error instanceof Error ? error?.message : 'An unknown error occurred';
        this._errorModalService.triggerErrorModal(errorMessage);
      }
      this._loaderService.requestHideLoader();
    }
  }

  //Adding the updating plan option, not sure if SAP will need to track these things....
  updatePlan(): void {
    const updatePlanAsync = async () => {
      this._loaderService.requestShowLoader();
      const newPlanID: string = this.plan.content.tiers[this.selectedItem].id;
      await this._cart.replace(this.preSelectedItemId, newPlanID);  
      this._loaderService.requestHideLoader();
      this._toastService.triggerSuccessToast('You have successfully updated your plan.');
      this._router.navigate(['shopping-cart']);
    };
    updatePlanAsync();
  }

  maintainPlanContent(): void {
    this._router.navigate(['shopping-cart']);
  }

  goPrevious(): void {
    this.location.back(); 
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
  }
}



// TODO: move to models
export interface EdsSelectOption {
  id: number,
  displayText: string,
  value: string,
}

