import { SourceType } from 'src/app/Models/cart.models';
import { AddressResult } from 'src/app/address-search/address-search.component';

export class Customer {
  fullyAuthenticated: boolean;
  email?: string;
  registration?: string;
  businessPartnerID?: string;
  password?: string;
  firstName?: string;
  lastName?: string;
  phone?: Phone;
  address?: AddressResult;
  isResidential?: boolean;
  typeOfProperty?: TypeOfProperty;
  isOwner?: boolean;
  addedPlans?: string[] = [];
  addressNumber?:string;
  transferResponse?:string;
  expirationDate: string | null;
  externalId: string ;
  sourceType: SourceType;
  
  constructor(customer: Customer = undefined
  ) {
    if (customer) {
      this.fullyAuthenticated = customer.fullyAuthenticated;
      this.email = customer.email;
      this.businessPartnerID = customer.businessPartnerID;
      this.password = customer.password;
      this.firstName = customer.firstName;
      this.lastName = customer.lastName;
      this.phone = customer.phone;
      this.address = customer.address;
      this.isResidential = customer.isResidential;
      this.typeOfProperty = customer.typeOfProperty;
      this.isOwner = customer.isOwner;
      this.registration = customer.registration;
      this.addedPlans = customer.addedPlans;
      this.transferResponse = customer.transferResponse;
      this.addressNumber = customer.addressNumber;
      this.sourceType = customer.sourceType;

    } else {
      this.fullyAuthenticated = false;
      this.email = undefined;
      this.businessPartnerID = undefined;
      this.password = undefined;
      this.firstName = undefined;
      this.lastName = undefined;
      this.phone = undefined;
      this.address = undefined;
      this.isResidential = undefined;
      this.typeOfProperty = undefined;
      this.isOwner = undefined;
      this.addedPlans = [];
      this.registration = undefined;
      this.transferResponse = undefined;
      this.sourceType = undefined;
    }
  }

  public prettyAddress(): string {
    // TODO: fix issue where house number is duplicated
    const address = this.address?.house_number + ' ' + this.address?.street + ' ' + this.address?.city + ', ' + this.address?.state + ' ' + this.address?.zipCode;
    return address.includes('undefined') ? undefined : address
  }

  public isLoggedIn():boolean {
    return this.businessPartnerID != undefined && this.email != undefined;
  }

  public isAbleToSignOut(): boolean {
    // could probably optimize this but easier to follow I think this way
    if (this.sourceType == SourceType.DEONE) {
      return this.transferResponse == "YES" && this.isLoggedIn()
    } else if (this.sourceType == SourceType.MyAccount) {
      return this.transferResponse == "YES"
    } else {
      return false;
    }
  }

  public hasItemsInCart():boolean {
    return this.addedPlans.length > 0;
  }

  public hasValidLocation(): boolean {
     const isValidAddress:boolean = ( (this.address != undefined) &&
                                 this.address.zipCode != undefined &&
                                 this.address.city != undefined &&
                                 this.address.state != undefined &&
                                 this.address.street != undefined && 
                                 this.address.house_number != undefined)

      const _:boolean = ( (this.isOwner != undefined) &&
                                 this.isResidential != undefined &&
                                 this.typeOfProperty != undefined)
      // TODO: keeping the isOwner, isResidential, isTypeofProperty guard part of this off until we know more from business 
      // const isValid:boolean = (true && isValidAddress);
    //  return isValid

     return isValidAddress;
  }

  public hasConsented():boolean {
    const isLogged  = this.isLoggedIn();
    const consented = (this.transferResponse != undefined && this.transferResponse == 'YES');
    return (isLogged && consented);
  }


      /// add the matching profiles based on customer answers
      public get customerProfiles():string[] {
        const list:string[] = [];
        if(this.isOwner) {
          // Eligible_Landlord -> pass if the user marks they're owner
          list.push('Eligible_Landlord');
        }

        if(this.isResidential) {
          // Eligible_Residential -> pass if the user marks they're in a residential property
          list.push('Eligible_Residential');
        }

        if(this.typeOfProperty?.includes('Single Family')) {
          // Eligible_SingleFamily -> pass if the user marks they're in a single family home
          list.push('Eligible_SingleFamily');

        }

        if(this.typeOfProperty?.includes('Multi-family')) {
          // Eligibile_MultiFamily -> pass if the user marks they're in in a multi-family home
          list.push('Eligibile_MultiFamily');
        }

        if(this.typeOfProperty?.includes('Mobile Home')) {
          // Eligible_MobileHome -> pass if the user marks they're in a mobile home
          list.push('Eligible_MobileHome');
        }

        return list
      }
}

export class Phone {
  number: string;
  type: string;

  constructor(number: string = undefined, type: string = '') {
    this.number = number;
    this.type = type;
  }
}

export enum TypeOfProperty {
  singleFamily = 'Single Family',
  multiFamily = 'Multi-family',
  mobileHome = 'Mobile Home'
}
