import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PlanTile } from 'src/app/Models/HomeContent';
import { SharedModule } from 'src/app/shared/shared.module';
import { CacheService } from 'src/app/services/cache.service';
import { CartService } from 'src/app/services/shopping-cart/cart.service';
import { Subject, takeUntil } from 'rxjs';
import { Plan } from 'src/app/Models/PlansContent';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent implements OnInit, OnDestroy {
  tiles: PlanTile[] = [];
  protected _onDestroy = new Subject<void>();
  public plans: string[] = [];
  public alreadySelectedPlans: Plan[] = [];

  constructor(
    private _router: Router,
    private _cacheService: CacheService,
    private _cart: CartService
  ) {
  }

  ngOnInit(): void {
    this.tiles = this._cacheService.rawPlans;
    this._cart.items.pipe(takeUntil(this._onDestroy)).subscribe(plans => {
      this.plans = plans;
    });

    if(this.tiles.length == 0) {
      this._router.navigate(['unsupported'], { queryParams: { reason: 'not-eligible' } });
      return; 
    }
  }

  hasOverlap<T>(array1: T[], array2: T[]): boolean {
    return array1.some(item => array2.includes(item));
  }

  clickOnPlan(plan): void {
    this._router.navigate(['tier'], { queryParams: { plan: plan.id } });
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
  }
}
