import { Injectable } from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {catchError, EMPTY,  Observable} from 'rxjs';
import { AppConfig } from '../config/app.config';
import { ApiService } from './api.service';
import { switchMap } from 'rxjs/operators';
import {TokenService} from './token.service';
import {Router} from '@angular/router';
import {CustomerService} from './customer.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  isRefreshing = false

  // retrieves the static token that ships with the app
  private get staticJWTToken():string {
    return this.appConfig.getConfig('accessToken');
  }

  private get apiKey():string {
    return this.appConfig.getConfig('apiKey');
  }

  // retrieves the session token given to the app for the session
  private get sessionJWTToken():string {
    return sessionStorage.getItem('session_access_token');
  }

  /// checks to see if we have a valid session token
  private get _hasJWTSessionToken():boolean {
    const hasToken:boolean = this.sessionJWTToken != undefined;
    return hasToken;
  }


  constructor(
    private appConfig: AppConfig,
    private _apiService: ApiService,
    private _tokenService: TokenService,
    private router: Router,
    private _customerService: CustomerService) {
  }


  // redid this intercept - it now identifies the first api call and will get you a token when it does
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // if not an api call or the call for the auth token - handle straight away
    const apiURL = this.appConfig.getConfig('apiBaseUrl');
    const parsedUrl = new URL(apiURL);
    const domain =  parsedUrl.hostname;

    if (request.url.includes('auth/refresh-token') || !request.url.includes(domain)) {
      const refreshTokenToken =sessionStorage.getItem('session_refresh_token')
      const authReq = request.clone({ setHeaders: { 'x-api-key': this.apiKey,
          Authorization: 'Bearer ' + refreshTokenToken} });
      return next.handle(authReq);
    }

    const isTokenValid = this._tokenService.ensureValidToken();
    const isRefreshTokenValid = this._tokenService.endureValidRefreshToken();
    // else - check for if the JWT token has already been retrieved
    if (this._hasJWTSessionToken && !isTokenValid) {
        const authReq = request.clone({ setHeaders: {'x-api-key': this.apiKey,
            Authorization: 'Bearer ' + this.sessionJWTToken } });
        return next.handle(authReq).pipe(
          catchError((error)=>{
            console.log('error',error)
            return next.handle(authReq)
          })
        )
    } else if( isTokenValid && this.sessionJWTToken ){
      if(!isRefreshTokenValid){
        return this.fetchNewRefreshToken(request,next)
      }
      else{
        return this.handleWhenBothTokensExpired()
      }

    }  else {
      // its a public endpoint no need to add a jwt
      const authReq = request.clone({ setHeaders: {'x-api-key': this.apiKey} });
      return next.handle(authReq);
    }
  }

  fetchNewRefreshToken(request: HttpRequest<any>, next: HttpHandler) {
    this.isRefreshing = true
    return this._apiService.fetchRefreshToken().pipe(
      switchMap((_: { accessToken:string })=>{
          this.isRefreshing = false;
          const newRequest = request.clone({setHeaders: { 'x-api-key': this.apiKey ,
              Authorization: 'Bearer ' + this.sessionJWTToken }})
          return next.handle(newRequest);
        }
      ))
  }

  handleWhenBothTokensExpired() {
      this._customerService.resetCustomer();
      this.router.navigate(['/sign-in'])
      return EMPTY

  }
}
