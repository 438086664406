import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Customer, Address, Phone, storedUser, ResidenceClasification, FamilyType, User, Profile, HouseType } from '../shared/models/customer';
import { AddressResult } from '../address-search/address-search.component';
import { Tier } from '../Models/PlansContent';
import { SourceType } from '../Models/cart.models';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  public tiers: Tier[] = [];
  private _customer$ = new BehaviorSubject<Customer>(null);
  private _customer = new Customer();
  public get storedUser(): Customer {
    return storedUser();
  }


  constructor() {
    const customer = this.storedUser;    
    if (customer != undefined) {
      this._customer = customer;
      this.broadcastAccountChanges();
    }
  }

  public resetCustomer(): void {
    this._customer = new Customer();
    sessionStorage.clear();
    this.broadcastAccountChanges();
  }

  // If the sign in fails, we want to keep the location but wipe their email and BPID
  public resetSignInOfCustomer(): void {
    this._customer.email = undefined;
    this._customer.business_partner_id = undefined;
    this.broadcastAccountChanges();
  }

  get customer(): Observable<Customer> {
    return this._customer$.asObservable();
  }

  get isFullyAuthenticated(): boolean {
    return this._customer.fullyAuthenticated
  }



  public reloadCustomer(): Customer {
    /// reloads the user from the session storage
    this._customer = this.storedUser;
    this.broadcastAccountChanges();
    return this._customer;
  }



  updateAccountSourceType(sourceType: SourceType): SourceType {
    this._customer.state.sourceId = sourceType
    this.broadcastAccountChanges();
    return SourceType.DEONE
  }


  updateEligibility(result: AddressResult, clasification: ResidenceClasification, propertyType: FamilyType, houseType: HouseType): void {
    const address:Address = {addressNumber:'', houseNumber:result.house_number,  street:result.street, city:result.city, state:result.state, zip:result.zipCode};
    const profile:Profile = {custType:clasification, houseType:houseType, propertyType:propertyType};
    this._customer.replaceCurrentAddress(address);
    this._customer.replaceCurrentProfile(profile);
    this.broadcastAccountChanges();
  }

  updateAppartmentNumber(number: number): void {
    if (this._customer?.address?.street) {
      this._customer.apartment = number;
      this.broadcastAccountChanges();
    }
  }

  public updateIsFullyAuthenticated(isFullAuth: boolean): void {
    this._customer.fullyAuthenticated = isFullAuth;
    this.broadcastAccountChanges();
  }

  public updateEmail(email: string): void {
    this._customer.email = email;
    this.broadcastAccountChanges();
  }

  public updateRegistrationStatus(status: string): void {
    this._customer.registration = status;
    this.broadcastAccountChanges();
  }

  public updateName(firstName: string, lastName): void {
    this._customer.firstName = firstName;
    this._customer.lastName = lastName;
    this.broadcastAccountChanges();
  }

  public updatePassword(password: string): void {
    this._customer.password = password;
    this.broadcastAccountChanges();
  }

  public updatePhone(phone: Phone): void {
    this._customer.phone = phone;
    this.broadcastAccountChanges();
  }

  public updateCustomer(customer: Customer): void {
    this._customer = customer;
    this.broadcastAccountChanges();
  }

  public updateFromCustomer(customer: Customer): Customer{
    this._customer.updateFromCustomer(customer);
    this.broadcastAccountChanges();
    return this._customer;
  }

  public updateFromUser(user: User): Customer{
    this._customer.updateFromUser(user);
    this.broadcastAccountChanges();
    return this._customer;
  }

  public removeHouseNumber(houseNum: string, streetName: string): string {
    if (streetName.startsWith(houseNum)) {
      return streetName.slice(houseNum.length).trim();
    }
    return streetName;
  }

  public addExternalId(externalId: string, expirationDate: string) {
    this._customer.externalId = externalId;
    this._customer.expirationDate = expirationDate;
    this.broadcastAccountChanges();
  }

  private broadcastAccountChanges(): void {
    sessionStorage.setItem('customer', JSON.stringify(this._customer));
    this._customer$.next(this._customer);
  }
}
