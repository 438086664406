import { ShoppingCartInterface, CartSource, Cart } from './cart.interface';
import { BehaviorSubject, Observable, take } from 'rxjs';
import { ApiService } from '../api.service';
import { emptyOrderSummary, OrderSummary, SAPProductDetails } from 'src/app/pages/profile/order-summary/order-summary.module';
import { Customer, storedUser } from 'src/app/shared/models/customer';
import { SAPCartItemRequest, SAPCartItemResponse } from 'src/app/Models/cart.models';

/// SAP (Real) Cart
export class SAPCart implements ShoppingCartInterface {

  cartName:string = 'cart';

  private _items$ = new BehaviorSubject<string[]>([]);

  constructor(public _apiService: ApiService) {
    this._items$.next(this.products);
  }

  get source(): CartSource { return CartSource.SAP; }

  get items(): Observable<string[]> {
    return this._items$.asObservable();
  }

  private get storedCustomer(): Customer { return storedUser() }
  

  reload(): Promise<boolean> {
    return new Promise(async (resolve) => {
      const cartResponse:OrderSummary = await this._apiService.getSAPCart();
      if(!cartResponse) {
        this.clearLocalModel();
        resolve(false);
        return;
      }
      resolve(this.store(cartResponse));
    });
  }
  
  async remove(id: string): Promise<boolean> {
    return new Promise(async (resolve) => {
      const customer:Customer = this.storedCustomer;
      const sumary:OrderSummary = this.storedModel;
      const details:SAPProductDetails = sumary.productDetails.find(product => product.productCode === id);
      if(!details) { resolve(false); return; }
      const removeRequest:SAPCartItemRequest = {bpId: customer.business_partner_id, cartId: sumary.cartId,  premiseId: customer.address.addressNumber, code: id, entryNumber:details.entryNumber};
      this._apiService.removeSAPCartItem(removeRequest).pipe(take(1)).subscribe({
        next: removeResponse => {
          console.log(removeResponse);
          this.reload().then(success => resolve(success));
        }, error: error => {
          this.reload().then(success => resolve(success));
          console.log(error);
          resolve(false);
        }});
    });
  }
  

  async clear(): Promise<boolean> {
    return new Promise(async (resolve) => {
      const sumary:OrderSummary = this.storedModel;
      const clearAllRequest:SAPCartItemRequest = {bpId: this.storedCustomer.business_partner_id,  cartId: sumary.cartId, premiseId: this.storedCustomer.address.addressNumber};
      await this._apiService.clearSAPCart(clearAllRequest);
      this.reload().then(success => resolve(success));
    });
  }

  async add(item: string): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      const sumary:OrderSummary = this.storedModel;
      const customer:Customer = this.storedCustomer;
      const request:SAPCartItemRequest = {bpId: customer.business_partner_id, cartId: sumary.cartId, premiseId: customer.address.addressNumber, code: item};
      const response:SAPCartItemResponse  = await this._apiService.addSAPCartItem(request);
      if(response?.success) { 
        await this.reload();
        resolve(response.success);
      } else {
        reject(new Error(response?.friendlyErrorMessage ?? undefined));
      }
    });
  }
 

  async replace(itemToRemove: string, newItem: string): Promise<boolean> {
    return new Promise(async (resolve) => {
      // remove existing item
      const removeSuccess = await this.remove(itemToRemove);
      if(!removeSuccess) { resolve(false); return }

      // add new item
      const addSuccess = await this.add(newItem);
      resolve(addSuccess);
    });
  }

  isEmpty(): boolean {
    return this.products.length == 0;
  }

  hasPlan(item: string):boolean {
    return this.products.includes(item);
  }

  /// read the cached model
  private get storedModel():OrderSummary {
    const content = JSON.parse(sessionStorage.getItem(this.cartName));
    if(content == null) { return emptyOrderSummary; }
    return content.model as OrderSummary;
  }

  /// extracts the product codes from order summary model
  private get products():string[] {
    if(!this.storedModel || !this.storedModel.productDetails) { return []; }
    return this.storedModel.productDetails.map(product => product.productCode) || [];
  }

  /// store the model in session storage for local manipulation & notify subscribers
  private store(value:OrderSummary): boolean {
       const cart:Cart = {source: this.source, model: value};
       const content = JSON.stringify(cart);
       sessionStorage.setItem(this.cartName, content);
       const successAdding = this.storedModel != undefined;
       this._items$.next(this.products);
       return successAdding;
  }

  private clearLocalModel() {
    const cart:Cart = {source: this.source, model: []};
    const content = JSON.stringify(cart);
    sessionStorage.setItem(this.cartName, content);
    this._items$.next(this.products);
  }
}