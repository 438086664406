<div *ngIf="plan !== undefined" class="w-full flex flex-col md:flex-row px-48">
    <div class="w-full max-w-[250px] md:min-w-[555px] md:max-w-[555px]">
        <img class="min-w-full" src="{{plan.hero.image}}">
    </div>

    <div class="w-full md:w-full pl-0 md:pl-24">
        <div class="flex flex-col items-start mt-24 md:mt-[-8px]">
            <div class="flex flex-col items-start text-left">
                <h2 class="text-2xl md:text-4xl text-dukeDarkBlue pb-20 w-full">{{plan.content.planTile.name}}</h2>
                <p class="pb-20" id="description"> {{plan.content.planTile.description}}</p>
            </div>
            <p id="font-bold" class="text-xl pb-12 text-gray-darker">Select a plan</p>
            <div *ngFor="let plan of plan.content.tiers; index as i"
                class="flex-1 flex items-center justify-left h-full w-full clickable hover:cursor-pointer pb-12"
                (click)="clickOnActionButton(i)">
                <div [ngClass]="selectedItem === i ? 'border-linkBlue' : 'border-gray'"
                    class="flex flex-row md:flex-row text-left w-full border-2 rounded-md  p-12">
                    <div class="flex-col w-[100%]">
                        <div *ngIf="this.addedPlans.includes(plan.id)"
                            class="inline-flex items-center bg-[#e6f4e2] text-sm px-6 py-4 rounded-full">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="20px" height="20px">
                                <path
                                    d="M63.27,15.794c-.696-.981-1.824-1.564-3.027-1.563H17.087l-1.933-5.343v-.085c-.05-.126-.107-.25-.171-.369,0-.099-.099-.199-.156-.298s-.142-.199-.227-.298-.142-.185-.227-.27l-.27-.242-.27-.227-.327-.199-.284-.156-.355-.128-.327-.099H3.716C1.664,6.514,0,8.178,0,10.23s1.664,3.716,3.716,3.716h5.343l9.762,26.999c.552,1.448,1.946,2.4,3.496,2.387h30.352c1.586-.009,2.991-1.025,3.496-2.529l7.645-21.628c.382-1.148,.181-2.41-.54-3.382ZM22.203,46.515c3.029,0,5.485,2.456,5.485,5.485s-2.456,5.485-5.485,5.485-5.485-2.456-5.485-5.485,2.456-5.485,5.485-5.485Zm29.884,0c3.029,0,5.485,2.456,5.485,5.485s-2.456,5.485-5.485,5.485-5.485-2.456-5.485-5.485,2.456-5.485,5.485-5.485Z"
                                    fill="#367941" />
                            </svg>
                            <span class="pl-4 text-[#367941]">Plan is already in your cart</span>
                        </div>
                        <div class="text-xl font-[500]">{{plan.name}}</div>
                        <div class="text-base mt-10 mb-20">{{plan.description}}
                        </div>
                        <div>
                            <span class="font-[500] text-customGreyBold">Monthly Charge:</span>
                            ${{plan.cost}}
                        </div>
                    </div>
                    <div [ngClass]="selectedItem === i ? 'bg-teal-darker ring-0' : 'bg-white ring-2 ring-gray'"
                        class="flex flex-shrink-0 m-12 p-4 w-24 h-24 rounded-full transition-colors duration-800"
                        aria-hidden="true">
                        <span
                            [ngClass]="selectedItem === i ? 'transition-all duration-800 ease-out w-16' : 'transition-all duration-800 ease-out w-0'"
                            class="h-16 overflow-hidden">
                            <img class="p-px w-16 h-16 text-white fill-current" src="/assets/icons/check-base.svg"
                                viewBox="0 0 64 64" width="16" height="16" focusable="false" aria-hidden="true">
                        </span>
                    </div>
                </div>
            </div>
            <!--Adding plan button-->
            <div class="pb-36 justify-start sm:w-[350px]">
                <section class="mt-12">
                    <eds-button-group [primaryText]="buttonTitle" [secondaryText]="'Previous'"
                        (primaryClick)="handleMainButtonAction()" (secondaryClick)="goPrevious()">
                    </eds-button-group>
                    <!-- <eds-button-group [primaryText]="buttonTitle" [secondaryText]="'Previous'" (primaryClick)="handleMainButtonAction()"
                        (secondaryClick)="goPrevious()">
                    </eds-button-group> -->
                </section>
            </div>
        </div>
    </div>
</div>