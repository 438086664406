import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { Plan, Tier } from '../Models/PlansContent';
import { CartTrasnferRejectedPayload } from '../Models/cart.models';
import { PlanDetailsRequest } from '../pages/tier/plan-details-models';
import { ApiService } from './api.service';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root'
})
export class RemovedPlansModalService {

  constructor(
    private _apiService: ApiService,
  ) {}

  // emit value to this subject to have modal show
  private _removedPlansModal = new Subject<Tier[]>();

  get $removedPlansModal(): Observable<Tier[]> {
    return this._removedPlansModal.asObservable();
  } 

  // call this if you need to get tiers descriptions based off array of strings (product codes)
  async handlerTransferErrorResponses(rejectedCodes: string[]): Promise<void> { //make this an input of strings instead
    console.log('HANDLING THE TRANSFER ERROR RESPONSES NOW')
    const request: PlanDetailsRequest = { categoryID: '', productCodes: rejectedCodes };
    const failedPlan: Plan = await this._apiService.fetchPproductDetails(request);
    const tiers: Tier[] = failedPlan.content.tiers;

    console.log('handling the transfer error response and getting back the tiers: ', tiers);
    if (tiers.length > 0) {
      this._removedPlansModal.next(tiers);
    }
  }

  // if you already have tier info, you can call this func directly.  Currently not in use
  public triggerModal(removedPlans: Tier[]): void {
    this._removedPlansModal.next(removedPlans);
  }
}
