import { ShoppingCartInterface, CartSource, Cart } from './cart.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from '../api.service';

/// AWS (Dummy/Dynamo) Cart
export class AWSCart implements ShoppingCartInterface {

  cartName:string = 'cart';

  private _items$ = new BehaviorSubject<string[]>([]);
  private _business_partner_id:string = '';

  constructor(public _apiService: ApiService, business_partner_id:string) {
    this._business_partner_id = business_partner_id;
  }

  get source(): CartSource {
    return CartSource.AWS;
  }

  get items(): Observable<string[]> {
    return this._items$.asObservable();
  }

  reload(): Promise<boolean> {
    return new Promise(async (resolve) => {
      /// the customer object is the cart in AWS
      const content = await this._apiService.getAWSCart();
      resolve(this.store(content.products));
    }); 
  }
  
  async remove(id: string): Promise<boolean> {
    return new Promise(async (resolve) => {
       const response = await this._apiService.removeAWSCartItem({business_partner_id: this._business_partner_id, products:[id]});
        if(response) {
          resolve(this.store(this.cartItems.filter(item => item !== id)));
        } else {
          resolve(false);
        }
    });
  }

  async clear(): Promise<boolean> {
    return new Promise(async (resolve) => {
      const response = await this._apiService.clearAWSCart(this._business_partner_id);
      if(response) {
        resolve(this.store([]));
      } else {
        resolve(false); 
      }
    });
  }

  async add(item: string): Promise<boolean> {
    return new Promise(async (resolve) => {
      const response = await this._apiService.addAWSCartItem({business_partner_id: this._business_partner_id, products:[item]});
      const products = this.cartItems;
      // prevent duplicates
      if (!products.includes(item)) {
          products.push(item);
      }
      resolve(response ? this.store(products) : false);
    });
  }

  async replace(itemToRemove: string, newItem: string): Promise<boolean> {
    return new Promise(async (resolve) => {
      if(await this.remove(itemToRemove)) {
        if(await this.add(newItem)) {
          // remove existing locally
          const products = this.cartItems.filter(item => item !== itemToRemove);
          /// add new one locally
          products.push(newItem);

          resolve(this.store(products));
        }
      }
    });
  }

  isEmpty(): boolean {
    return this.cartItems.length == 0;
  }

  hasPlan(item: string):boolean {
    return this.cartItems.includes(item);
  }

  private get cartItems(): string[] {
    const raw = sessionStorage.getItem(this.cartName);
    if(raw) {
      const cart:Cart =  JSON.parse(raw) as Cart;
       return cart.model as string[];
    }
    return [];
  }

  /// store the model in session storage for local manipulation & notify subscribers
  private store(plans:string[]): boolean {
       const cart:Cart = {source: this.source, model: plans};
       const content = JSON.stringify(cart);
       sessionStorage.setItem(this.cartName, content);
       this._items$.next(plans);
       return true;
  }
}