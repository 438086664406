import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from '../config/app.config';
import { Observable, map } from 'rxjs';
import { Customer } from '../shared/models/customer';
import { TermsRequest, TermsResponse } from '../Models/terms.models';
import { EnrollmentRequest, EnrollmentResponse } from '../Models/enrollment.models';
import { LocalAWSCart, SourceType, SAPCartRequest, SAPCartResponse } from '../Models/cart.models';
import { SignInResponse } from '../Models/SignInResponse';
import { AddressValidationRequest, AddressValidationResponse } from '../pages/location-check/location-models';
import { EligibilityRequest } from '../pages/home/home-models';
import { EligiblityResponse, PlanTile } from '../Models/HomeContent';
import { PlanDetailsRequest } from '../pages/tier/plan-details-models';
import { OrderSummary } from '../pages/profile/order-summary/order-summary.module';

const APPLICATION_JSON = 'application/json';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private _apiBaseUrl = '';

  constructor(
    private _httpClient: HttpClient,
    private _config: AppConfig) {
      this._apiBaseUrl = this._config.getConfig('apiBaseUrl');
   }

   public getNewToken(): Observable<any> {
    const headers = { 'Content-Type': APPLICATION_JSON }
    return this._httpClient.get<any>(`${this._apiBaseUrl}auth/token`,{headers})
    .pipe(map((response:any) => {
      const token = response?.token;
      const refreshToken = response?.refresh_token;
      sessionStorage.setItem('session_access_token',token)
      sessionStorage.setItem('session_refresh_token',refreshToken)
      return response;
    }))
  }
  public fetchRefreshToken(): Observable<any> {
    return this._httpClient.get<any>(`${this._apiBaseUrl}auth/refresh-token`, )
      .pipe(map((response:any) => {
        const token = response?.token;
        const refreshToken = response?.refresh_token;
        sessionStorage.setItem('session_access_token',token)
        sessionStorage.setItem('session_refresh_token',refreshToken)
        return response;
      }))
  }
   public signInCustomer(username: string, password: string): Observable<SignInResponse> {
    const headers = { 'Content-Type': APPLICATION_JSON }
    const body = {
      u: username,
      p: password
    };

    return this._httpClient.post<any>(`${this._apiBaseUrl}auth/sign-in`, body, {headers})
    .pipe(map((response) => {
      const updatedToken = response?.updatedToken;
      const refreshToken = response?.refresh_token;
      sessionStorage.setItem('session_access_token',updatedToken)
      sessionStorage.setItem('session_refresh_token',refreshToken)
      return response as SignInResponse
    }));
   }

   public recordTransferProfileResponse(transferResponse: string): Observable<string> {
    const headers = { 'Content-Type': APPLICATION_JSON }
    const body = {
      transferResponse: transferResponse
    };

    return this._httpClient.post<any>(`${this._apiBaseUrl}customer/transfer-profile`, body, {headers})
    .pipe(map((response) => {
      return response as string
    }));
   }

   public createProfile(customer: Customer): Observable<string> {
    const headers = { 'Content-Type': APPLICATION_JSON }
    const body = {
      email: customer?.email,
      password: customer?.password,
      firstName: customer?.firstName,
      lastName: customer?.lastName,
      phoneNumber: customer?.phone.number,
      phoneType: customer?.phone.type
    };
    return this._httpClient.post<any>(`${this._apiBaseUrl}customer/create-profile`, body, {headers})
    .pipe(map((response) => {
      return response as string
    }));
   }


   public isRegistered(email: string): Observable<string> {
    const headers = { 'Content-Type': APPLICATION_JSON }
    const body = { email: email };
    return this._httpClient.post<any>(`${this._apiBaseUrl}customer/verify-registered-email`, body, {headers})
    .pipe(map((response) => {
      return response as string
    }));
   }
   public sendValidationEmail(email: string): Observable<string> {
    const headers = { 'Content-Type': APPLICATION_JSON }
    const body = { email: email };
    return this._httpClient.post<any>(`${this._apiBaseUrl}customer/send-validation-email`, body, {headers})
    .pipe(map((response) => {
      return response as string
    }));
   }

   public updateTermsAndConditions(request: TermsRequest): Observable<string> {
    const headers = { 'Content-Type': APPLICATION_JSON }
    return this._httpClient.post<any>(`${this._apiBaseUrl}customer/terms`, request, {headers})
    .pipe(map((response) => {
      return response as string
    }));
   }

   public getTermsAndConditions(): Observable<TermsResponse> {
    const headers = { 'Content-Type': APPLICATION_JSON }
    const options = { headers: headers };
    return this._httpClient.get<any>(`${this._apiBaseUrl}customer/terms`, options)
    .pipe(map((response) => {
      return response as TermsResponse
    }));
   }

   public updateEnrollment(request: EnrollmentRequest): Observable<string> {
    const headers = { 'Content-Type': APPLICATION_JSON }
    return this._httpClient.post<any>(`${this._apiBaseUrl}customer/enrollment`, request, {headers})
    .pipe(map((response) => {
      return response as string
    }));
   }

   public getEnrollment(): Observable<EnrollmentResponse> {
    const headers = { 'Content-Type': APPLICATION_JSON }
    const options = { headers: headers };
    return this._httpClient.get<any>(`${this._apiBaseUrl}customer/enrollment`, options)
    .pipe(map((response) => {
      return response as EnrollmentResponse
    }));
   }


   private buildLocalCartFrom(customer: Customer, source:SourceType): LocalAWSCart {
    const cart:LocalAWSCart=    {
      'house_number':        customer.address?.house_number ?? '',
      'opened_in_sap':       false,
      'zip':                 Number(customer.address?.zipCode) ?? 0,
      'is_residential':      customer.isResidential ?? false,
      'address':             customer.address?.street ?? '',
      'business_partner_id': customer.businessPartnerID ?? '',
      'email':               customer.email ?? '',
      'address_2':           customer.address?.apartment ?? '',
      'state':               customer.address?.state ?? '',
      'city':                customer.address?.city ?? '',
      'sap_cart_id':         null,
      'products':            customer.addedPlans ?? [],
      'property_type':       customer.typeOfProperty ?? '',
      'own_or_rent':         customer.isOwner ? 'own' : 'rent',
       'date':               null,
       'source_id':         source
     };

     return cart
   }
   public updateAWSLocalCartWithCustomer(customer: Customer, source:SourceType): Observable<string> {
     const request:LocalAWSCart = this.buildLocalCartFrom(customer, source);
     return this.updateAWSLocalCartWithRequest(request);
   }

   public updateAWSLocalCartWithRequest(request: LocalAWSCart): Observable<string> {
    const headers = { 'Content-Type': APPLICATION_JSON }
    return this._httpClient.post<any>(`${this._apiBaseUrl}customer/data`, request, {headers})
    .pipe(map((response) => {
      return response as string
    }));
   }

   public getLocalAWSCart(email: string): Observable<LocalAWSCart> {
    const params = new HttpParams().set('email', email);
    const options = { params: params };
    return this._httpClient.get<any>(`${this._apiBaseUrl}customer/data`, options)
    .pipe(map((response) => {
      return response as LocalAWSCart
    }));
   }

   public updateSAPCart(customer:Customer, source:SourceType): Observable<[SAPCartResponse]> {
    const request:SAPCartRequest = {
      address_number: customer.addressNumber,
      cart: this.buildLocalCartFrom(customer, source)
    }

    const headers = { 'Content-Type': APPLICATION_JSON }
    return this._httpClient.post<any>(`${this._apiBaseUrl}shopping-cart/add-item`, request, {headers})
    .pipe(map((response) => {
      return response as [SAPCartResponse]
    }));
   }
  public fetchSAPCart(): Observable<OrderSummary> {
    return this._httpClient.get(`${this._apiBaseUrl}shopping-cart/data`).pipe(
      map((response) => {
        return response as OrderSummary
      })
    )
  }

   public fetchSpeedPayRedirect(businessPartnerId: string): Observable<any> {
     const params = new HttpParams().set('business-partner-id', businessPartnerId);
     const options = { params: params};
     return this._httpClient.get(`${this._apiBaseUrl}speed-pay/redirect`,options).pipe(
       map((response) => {
         return response
       })
     )
   }
   public fetchPlanTiers(request:PlanDetailsRequest): Observable<any> {
    const headers = { 'Content-Type': APPLICATION_JSON }
     return this._httpClient.post(`${this._apiBaseUrl}customer/product-details`,request, {headers}).pipe(
       map((response) => {
         return response
       })
     )
   }

   public validateServiceAddress(request: AddressValidationRequest): Observable<AddressValidationResponse> {
    const headers = { 'Content-Type': APPLICATION_JSON }
    return this._httpClient.post<any>(`${this._apiBaseUrl}customer/validate-address`, request, {headers})
    .pipe(map((response) => {
      return response as AddressValidationResponse
    }));
   }

  getPlans(request:EligibilityRequest): Observable<EligiblityResponse> {
    const headers = { 'Content-Type': APPLICATION_JSON }
    return this._httpClient.post<EligiblityResponse>(`${this._apiBaseUrl}customer/eligibility`, request, {headers})
    .pipe(map((response) => {
      return response as EligiblityResponse
    }));
  }
}
