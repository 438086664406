import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PlanTile } from 'src/app/Models/HomeContent';
import { ApiService } from 'src/app/services/api.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { AccountProfile, EligibilityRequest } from './home-models';
import { CustomerService } from 'src/app/services/customer.service';
import { Customer } from 'src/app/shared/models/customer';
import { LoaderService } from 'src/app/services/loader.service';
import { ToastService } from 'src/app/services/toast.service';
import { take } from 'rxjs';
import { CacheService } from 'src/app/services/cache.service';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent implements OnInit {
  tiles: PlanTile[] = [];

  constructor(
    private _router: Router,
    private _api: ApiService,
    private _customerService: CustomerService,
    private _loaderService: LoaderService,
    private _cache: CacheService,
    private _toastService: ToastService) {

  }

  ngOnInit(): void {
    this.getEligiblePlans();
  }

  getEligiblePlans() {
    // craft the request
    const customer: Customer = this._customerService.reloadCustomer();
    const accountProfiles: AccountProfile[] = customer.customerProfiles.map(str => ({ Profile_Name: str }));
    const request: EligibilityRequest = { account_number: '', business_partner: customer.businessPartnerID ?? '', zipcode: customer.address?.zipCode, account_profile: accountProfiles };
    this._cache.rawPlans ? this.tiles = this._cache.rawPlans : this.fetchPlans(request)

  }

  fetchPlans(request) {
    this._loaderService.requestShowLoader();
    this._api.getPlans(request).pipe(take(1)).subscribe({
      next: response => {
        this.validateAlreadyAddedPlans(response.products);
        this._loaderService.requestHideLoader();
        this.tiles = response.products;
        this._cache.savePlans(response.products);
        if (response.products.length == 0) {
          this._router.navigate(['unsupported'], { queryParams: { reason: 'not-eligible' } });
        }
      },
      error: err => {
        this._loaderService.requestHideLoader();
        this._toastService.triggerErrorToast('Unknown Error Getting plans');
        console.error(err);
      }
    });
  }

  validateAlreadyAddedPlans(products: any) {
    this._customerService.validateAlreadyAddedPlans(this.getUniqueProductCodes(products))
  }

  getUniqueProductCodes(products: any): string[] {
    let allCodes = [];

    for (const product of products) {
      if (product.productCodes) {
        allCodes = allCodes.concat(product.productCodes);
      }
    }

    // Remove duplicates from array by using Set
    return Array.from(new Set(allCodes));
  }

  clickOnPlan(plan): void {
    this._router.navigate(['tier'], { queryParams: { plan: plan.id } });
  }
}
