import { Injectable } from '@angular/core';
import { EligiblityResponse, PlanTile } from '../Models/HomeContent';
import { BehaviorSubject, Observable } from 'rxjs';
import {Plan, Tier} from '../Models/PlansContent';
import { Customer } from '../shared/models/customer';
import { AccountProfile, EligibilityRequest } from '../pages/home/home-models';
import { ApiService } from './api.service';
import { ShoppingCartInterface } from './shopping-cart/cart.interface';
import { CartService } from './shopping-cart/cart.service';
import { RemovedPlansModalService } from './removed-plans-modal.service';

@Injectable({
    providedIn: 'root'
})
export class CacheService {

  private _formData: any;
  private _plans = new BehaviorSubject<PlanTile[]>([]);
  // private _removedPlans = new BehaviorSubject<string[]>([]);

  constructor(
    private _removedPlansModalService: RemovedPlansModalService,
  ) {
    const plans = JSON.parse(this.retrieve('plans'));
    if (this._plans) {
      this._plans.next(plans);
    }
   }

  public setFormData(formData: any): void {
    this._formData = formData;
  }

  public getFormData(): any {
    return this._formData;
  }
  public savePlans(plans:PlanTile[]) {
    const content = JSON.stringify(plans);
    if(this.store('plans', content)){
      this._plans.next(plans);
    }
  }

  public get rawPlans():PlanTile[] {
    const stringData = sessionStorage.getItem('plans');
    const data:PlanTile[] = JSON.parse(stringData) || [];
    return data
  }

  public getFamilyPlanID(productCode: string): string{
    const familyId = '';
    const currentPlans = this.rawPlans;
    for (const plan of currentPlans){
       for (const code of plan.productCodes) {
          if (code.toUpperCase() == productCode.toUpperCase()) {
              return plan.id
          }
       }
    }

    return familyId;
  }

  public getProductTier(shortCode: string): Tier{
    // TODO: make all codes uppercase
    shortCode = shortCode.toUpperCase();
    const currentPlans = this.rawPlans;
    const categoryID   = this.getFamilyPlanID(shortCode);
    const plan:PlanTile = currentPlans.find(plan => plan.id === categoryID);
    const tier:Tier = plan?.productDetails?.content?.tiers?.find(tier => tier?.id === shortCode)
    return tier;
  }


  public getProductDetail(categoryID: string): Plan{
    const currentPlans = this.rawPlans;
    const plan:PlanTile = currentPlans.find(plan => plan.id === categoryID);
    return plan.productDetails;
  }

  private store(key:string, value:string): boolean {
    sessionStorage.setItem(key, value);
    return sessionStorage.getItem(key).length > 0;
  }

  private retrieve(key:string): any {
    return sessionStorage.getItem(key);
  }


  get plans(): Observable<PlanTile[]> {
    return this._plans.asObservable();
  }

  // get removedPlans(): Observable<string[]> {
  //   return this._removedPlans.asObservable();
  // }


  extractUniqueProductCodes(products: PlanTile[]): string[] {
    let allCodes = [];

    for (const product of products) {
      if (product.productCodes) {
        allCodes = allCodes.concat(product.productCodes);
      }
    }

    // Remove duplicates from array by using Set
    return Array.from(new Set(allCodes));
  }

  public async getEligiblePlans(customer:Customer, api:ApiService, cart:CartService):Promise<EligiblityResponse> {
      // craft the request
      const accountProfiles: AccountProfile[] = customer.customerProfiles.map(str => ({ Profile_Name: str }));
      const request: EligibilityRequest = { account_number: '', business_partner: customer.business_partner_id ?? '', zipcode: customer.address.zip.toString(), account_profile: accountProfiles };
      let response:EligiblityResponse = await api.fetchPlans(request);
      if (response == undefined) {
        response = { products: [] };
      }
      
      const plans = response.products.flatMap(element => element.productCodes)
      const [newlyFilteredPlans, removedPlans] = this.removeInEligiblePlans(plans, cart.currentItems)

      if (removedPlans.length > 0) {
        removedPlans.forEach(item => {
          cart.remove(item);
        });
        await this._removedPlansModalService.handlerTransferErrorResponses(removedPlans);
      }


      this.savePlans(response.products);
      return response;
    }

    public removeInEligiblePlans(eligiblePlans: string[], cartItems: string[]): [string[], string[]] {
      const removedItems: string[] = [];
      const newFilteredPlans = cartItems.filter(item => {
          if (eligiblePlans.includes(item)) {
              return true;
          } else {
              removedItems.push(item);
              return false;
          }
      });
      return [newFilteredPlans, removedItems];
  }
     
}
