
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Plan, Tier } from 'src/app/Models/PlansContent';
import { SharedModule } from 'src/app/shared/shared.module';
import { Customer } from 'src/app/shared/models/customer';
import { ToastService } from 'src/app/services/toast.service';
import { CustomerService } from 'src/app/services/customer.service';
import { Subject, takeUntil } from 'rxjs';
import { ModalMessageComponent } from 'src/app/components/modal-message/modal-message.component';
import { CacheService } from 'src/app/services/cache.service';
import { SourceType } from 'src/app/Models/cart.models';
import { PlanTile } from 'src/app/Models/HomeContent';
import { LoaderService } from 'src/app/services/loader.service';
import { CartService } from 'src/app/services/shopping-cart/cart.service';


@Component({
  selector: 'app-shopping-cart',
  standalone: true,
  imports: [SharedModule, ModalMessageComponent],
  templateUrl: './shopping-cart.component.html',
  styleUrl: './shopping-cart.component.scss',
})
export class ShoppingCartComponent implements OnInit, OnDestroy {

  plan: Plan;
  public selectedItem = -1;
  public tiers: Tier[] = [];

  public unselectedPlans: Plan[] = [];

  public customer: Customer;
  public plans: string[] = [];
  public isTooltipClicked = false;
  public items: PlanTile[] = [];
  public plansInTotal: any[] = [];
  protected _onDestroy = new Subject<void>();

  constructor(
    private _router: Router,
    private _toastService: ToastService,
    private _customerService: CustomerService,
    private _loaderService: LoaderService,
    private _cart: CartService,
    private _cacheService: CacheService
  ) {
  }

  public get subTotal(): number {
    return this.tiers.map((tier) => Number(tier?.cost)).reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  }


  public get monthlyCost(): number {
    return this.subTotal;
  }

  public toggleTooltip(): void {
    this.isTooltipClicked = !this.isTooltipClicked;
  }

  public closeTooltip(): void {
    this.isTooltipClicked = false;
  }

  tempReload() {
    console.log(this._cart.source);
    this._cart.reload();
  }

  ngOnInit(): void {

    this._customerService.customer.pipe(takeUntil(this._onDestroy)).subscribe(cust => {
      this.customer = cust;

      if (this.customer.state.sourceId == SourceType.MyAccount && !this.customer.hasConsented) {
        this._router.navigate(['/profile/transfer']);
      }
    });

    this._cart.items.pipe(takeUntil(this._onDestroy)).subscribe(plans => {
      this.tiers = plans.map((shortCode) => this._cacheService.getProductTier(shortCode));
      this.plans = plans;
      this.findAndSortRecommendedPlans(plans);
    });
  }

  public findAndSortRecommendedPlans(existingPlans:string[]): void {
    this.unselectedPlans = [];
      //find plans to show at the bottom in the recommended plans section
      const plans:Plan[] = this._cacheService.rawPlans.map(tile => tile?.productDetails);

      plans.forEach(item => {
        if (!this.hasCommonElement(item.content.tiers.map(tier => tier.id), existingPlans)) {
          this.unselectedPlans.push(item);
        }
      });

      // sort plans by highest item ranking
      this.unselectedPlans.sort((a, b) => this.findHighestRanking(a.content.tiers, 'ranking') - this.findHighestRanking(b.content.tiers, 'ranking'));
  }

  // func used for finding highest ranked item in an array (for sorting recommended plans)
  findHighestRanking(arr: any[], prop: string): any {
    let highestValue = arr[0][prop];

    for (let i = 1; i < arr.length; i++) {
      if (arr[i][prop] > highestValue) {
        highestValue = arr[i][prop];
      }
    }
    return highestValue;
  }

  // func to search if an item is already there.  Used to determine what plans are in cart vs what plans can be recommended
  public hasCommonElement(arr1: any[], arr2: any[]): boolean {
    return arr1.some(value => arr2.includes(value));
  }


  //This function calls the removePlan method for customerService
  async remove(planId: string): Promise<void> {
    try {
      this._loaderService.requestShowLoader();
      const result = await this._cart.remove(planId);
      result ? console.info('Plan removed successfully') : console.error('Error removing plan');
      this._loaderService.requestHideLoader();
    } catch (error) {
      // Handle error
      console.error('Error removing plan:', error);
      this._loaderService.requestHideLoader();
    }
  }

  changePlan(id: any) {
    const familyID = this._cacheService.getFamilyPlanID(id);
    this._router.navigateByUrl('/tier?plan=' + familyID);
  }

  continue(): void {
    this._router.navigate(['home']);
  }

  checkout(): void {
    if (this.tiers.length < 1) {
      this._toastService.triggerErrorToast('Your cart is empty');
      return;
    }

    if (this.customer == undefined || !this.customer.isLoggedIn) {
      // user has not signed in yet show the login screen options
      this._router.navigate(['checkout/home']);
      return;
    }

    if (this.customer.state.sourceId == SourceType.DEONE) {
      /// deone customers don't need to transfer
      this._router.navigate(['checkout/speed-pay']);
      return;
    }

    if (this.customer.state.acceptedTransfer && this.customer.state.sourceId == SourceType.MyAccount) {
      /// my account user is already signed in and accepted to transfer go to speed pay
      this._router.navigate(['checkout/speed-pay']);
      return;
    }

    /// user is my account and  has not yet accepted the tranfer consent
    this._router.navigate(['checkout/transfer']);
  }

  routeToPlan(url: string): void {
    this._router.navigateByUrl(url);
  }

  onClickTCLink() {
    const url =
      'https://www.duke-energy.com/-/media/pdfs/unindexed/231873-8-terms-and-conditions-my-energy-bill.pdf?rev=010eacb1babb47239de6ca832db6ce4d';
    window.open(url, '_blank');
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
  }
}
