<div class="flex flex-col m-0 p-0 h-screen min-w-[370px]">
  <app-one-header
    [actionButtons]="headerActionButtons"
    [currentNavGroup]="[0]"
    
  ></app-one-header>

  <main class="flex-1 mt-36">
    <router-outlet></router-outlet>
  </main>
  <app-disclaimer></app-disclaimer>

  <!-- isInternal=true hides the company slogan -->
  <eds-site-footer class="" [isInternal]="true"></eds-site-footer>
</div>

<de-loader *ngIf="isLoading" [showOverlay]="true"></de-loader>

<eds-toast
  [type]="toast?.type"
  [message]="toast?.message"
  [showToast]="showToast"
  [durationInMs]="5000"
  [allowDismiss]="true"
  (toastClosed)="showToast = false"
>
</eds-toast>

  <!-- Error Modal -->
  <eds-modal [contentTemplate]="modalTemplate" [isOpen]="isErrorModalOpen"
    [allowClose]="true" primaryBtnText="" secondaryBtnText="" title=""
    (modalClosed)="errorModalClosed()"></eds-modal>
  
  <ng-template #modalTemplate>
    <div class="gap-16 max-w-xl">
      <ng-container>

        <div class="flex flex-col items-center text-center">
          <div class="max-w-xl rounded-md sm:mt-48 p-12 sm:py-36 sm:px-96">
              <svg class="w-48 h-48 m-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76 76"><path d="M38,0A38,38,0,1,0,76,38,38,38,0,0,0,38,0ZM32.7,19.984a5.984,5.984,0,0,1,11.968,0v.14a5.984,5.984,0,0,1-11.968,0Zm11.979,38.36A4.657,4.657,0,0,1,40.02,63H37.365a4.657,4.657,0,0,1-4.656-4.656V34.689a4.656,4.656,0,0,1,4.656-4.656H40.02a4.656,4.656,0,0,1,4.656,4.656Z" fill="#860000"/></svg>
      
              <div class="text-2xl mt-12 text-[#860000]">
                  {{this.errorModalMessage ?? 'An error has occured. Please try again.'}}
              </div>
          </div>
      </div>

      </ng-container>
    </div>
  </ng-template>



    <!-- Removed Plans Modal -->
    <eds-modal [contentTemplate]="removedPlansModalTemplate" [isOpen]="isRemovedPlansModalOpen"
    [allowClose]="true" primaryBtnText="" secondaryBtnText="" 
    [iconSrc]="modalIcon.src"
    [title]="'Some plan(s) have been removed from your cart.'"
    [titleTwClasses]="'container-lg'"
    [subTitleTwClasses]="'container-md'"
    [subTitle]="'We are sorry due to eligibility the following plan(s) have been removed from your cart and are no longer available to be purchased.'"
    (modalClosed)="removedPlansModalClosed()"></eds-modal>
  
  <ng-template #removedPlansModalTemplate>
    <div class="gap-16 max-w-xl">
      <ng-container>
        
        <div
        *ngFor="let plan of removedTiers"
        class="items-center justify-left pb-12 text-left border-[#dfe4e4] border rounded-md md:p-16 p-10 pt-20"
      >
        <div class="flex md:flex-none">
          <img class="icon-80 my-20" src="{{ plan.image }}" alt="plan-icons" />
          <div class="flex flex-col">
            <div class="flex flex-col">
              <div class="ml-32 text-lg text-teal-darker font-bold">
                {{ plan.name }}
              </div>
              <div
                class="font-normal md:container-sm ml-32 text-lg text-gray-dark"
              >
                <p>Power surges are unpredictable and can damage your appliances
                  and electronics. Get repair or replacement reimbursement for covered items.
                </p>
              </div>
            </div>

          </div>
        </div>
    </div>

      </ng-container>
    </div>
  </ng-template>
