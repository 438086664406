import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { CustomerService } from './services/customer.service';
import { Customer } from './shared/models/customer';

@Injectable({
  providedIn: 'root'
})

// add this guard when you want a page to be skipped if the user is already logged in
export class UserIsLoggedInGuard implements CanActivate {

  constructor(private _customerService:CustomerService, private router: Router) {
  }

  canActivate(): boolean {
    const customer:Customer = this._customerService.reloadCustomer();
    const isLoggedIn = customer != undefined && customer.isLoggedIn;
    if(isLoggedIn) {
      // user is already logged in redirect to home
      this.router.navigate(['/home']);
    }

    return !isLoggedIn;
  }
}