import { Observable } from 'rxjs';


export enum CartSource {
    session = 'session',
    AWS = 'AWS',
    SAP = 'SAP'
}

export interface ShoppingCartInterface {
    // current cart name
    get source(): CartSource;
    
    // get all items in the cart as an observable
    get items(): Observable<string[]>;

    // fetches cart data from source
    reload(): Promise<boolean>;

    // remove an item from the cart
    remove(itemId: string): Promise<boolean>;

    // removes all items from the cart
    clear(): Promise<boolean>;

    // add an item to the cart
    add(item: string): Promise<boolean>;

    // replace an item in the cart
    replace(itemToRemove: string, newItem: string): Promise<boolean>;

    // check if the cart is empty
    isEmpty():boolean;

    // true if the cart has a specific item
    hasPlan(id: string):boolean;
}
export class Cart {
    source: CartSource;
    model: any;
     constructor(source: CartSource, model: any) {
        this.source = source;
        this.model = model;
    }
}