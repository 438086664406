import { ShoppingCartInterface, CartSource, Cart } from './cart.interface';
import { BehaviorSubject, Observable } from 'rxjs';

/// Session (in the browser / local only) Cart
export class SessionCart implements ShoppingCartInterface {

  cartName = 'cart';

  private _items$ = new BehaviorSubject<string[]>([]);

  constructor() { 
    this._items$.next(this.storedPlans);
  }

  get source(): CartSource {
    return CartSource.session;
  }

  // Session Storage - retrieve cart from session
  private get storedPlans(): string[] {
    const content = JSON.parse(sessionStorage.getItem(this.cartName));
    if(content == null) {
      return [];
    }

    return content.model as string[];
  }

  reload(): Promise<boolean> {
    return new Promise((resolve) => {
      this._items$.next(this.storedPlans);
      resolve(true);
    });
  }

  // service after construction keeps track of its own items - grab local var instead of going to session
  get items(): Observable<string[]> {
    return this._items$.asObservable();
  }
  
  async remove(id: string): Promise<boolean> {
    return new Promise((resolve) => {
      const updated  = this.storedPlans.filter(item => item !== id);
      resolve(this.store(updated));
    });
  }

  async clear(): Promise<boolean> {
    return new Promise((resolve) => {
      resolve(this.store([]));
    });
  }

  async add(item: string): Promise<boolean> {
    return new Promise((resolve) => {
      const plans = this.storedPlans;
      // don't duplicate values
      if (!plans.includes(item)) {
        plans.push(item);
      }
      resolve(this.store(plans));
    });
  }

  async replace(itemToRemove: string, newItem: string): Promise<boolean> {
    return new Promise(async (resolve) => {
      const isSuccessfulRemove = await this.remove(itemToRemove);
      const isSuccessfulAdd = await this.add(newItem);
      const isSuccessfulReplace = isSuccessfulRemove && isSuccessfulAdd;
      resolve(isSuccessfulReplace);
    });
  }

  isEmpty(): boolean {
    return this.storedPlans.length == 0;
  }

  hasPlan(item: string):boolean {
    return this.storedPlans.includes(item);
  }

    // Session Storage - store cart in session & notify changes
    private store(value:string[]): boolean {
      const cart:Cart = {source: this.source, model: value};
      const content = JSON.stringify(cart);
      sessionStorage.setItem(this.cartName, content);
      const success:boolean = this.storedPlans != undefined;
      this._items$.next(cart.model as string[]);
      return success;
    }
}