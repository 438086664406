import {Injectable} from '@angular/core';


export interface OrderSummary {
  cartId: string | null;
  recurringPrice: number;
  totalItems: number;
  totalPrice: number;
  totalPriceWithTax: number;
  totalTax: number;
  productDetails: SAPProductDetails[]
}

export interface SAPProductDetails {
  action: string;
  cost: number;
  description: string;
  entryNumber:number;
  id: string;
  image: string;
  name: string;
  productCode: string;
  productName: string;
  quantity: number;
  ranking: number;
  recurringPrice: number;
  taxPrice: number;
  termsAndConditions: string[];
  totalPrice: number;
}

// TODO: this is temporary once we switch over to use the SAP cart it will return all this data for us
@Injectable({
  providedIn: 'root'
})

export class OrderSummaryService{

  calculateSubTotal(tiers:any): number {
    return tiers.map((tier) => Number(tier.cost)).reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  }

   taxes(subTotal: number): number {
    const taxes =  subTotal * 0.2; // replace with actual value with integration
    return Number(taxes.toFixed(2));
  }

   monthlyCost(subTotal:number ): number {
    return subTotal + this.taxes(subTotal);
  }
}


export const emptyOrderSummary: OrderSummary = {
  cartId: null,
  recurringPrice: 0,
  totalItems: 0,
  totalPrice: 0,
  totalPriceWithTax: 0,
  totalTax: 0,
  productDetails: []
};