<ng-container>
  <header
    class="site-header__container sticky top-auto z-header p-8 border-b border-gray md:mt-10"
    [ngClass]="[
      this.justScrolledDown && this.allowScrollAnimation
        ? '-top-[175px]'
        : 'top-0',
      this.hasScrolledDown ? 'shadow' : ''
    ]"
  >
    <ng-container>
      <div class="site-header__action-bar flex bg-white text-blue md:ml-6">
        <div class="site-header__action-bar--left flex items-center">
          <button
            id="hamburger-trigger--external"
            class="btn btn-tertiary btn-icon-only btn-small mx-4"
            aria-label="Open Navigation Drawer"
            aria-controls="nav-drawer"
            [attr.aria-expanded]="isHamburgerMenuOpen"
            (click)="toggleHamburgerMenuOpen()"
          >
            <eds-svg
              svgPath="components/assets/icons/hamburger-menu.svg"
            ></eds-svg>
          </button>

          <eds-svg
            [svgPath]="
              headerIcon?.path ?? '/assets/icons/duke-energy-one-logo.svg'
            "
            [svgSizeClass]="headerIcon?.sizeClass ?? 'icon-72'"
          ></eds-svg>
          <div
            class="site-header__title text-title border-l border-gray ml-10 pl-12 md:text-xl text-lg"
          >
            {{ siteTitle }}
          </div>
        </div>

        <!--Nav Bar-->
        <nav aria-label="navbar" class="navbar px-6 md:ml-auto sm:py-20">
          <div class="container flex justify-between items-center gap-40">
            <span *ngIf="hasLocation">
              <div class="flex items-center md:ml-64 gap-6">
                <eds-svg
                  [svgPath]="'assets/icons/shield-base.svg'"
                  [attributes]="{ fill: 'currentColor' }"
                  [svgSizeClass]="'icon-24'"
                ></eds-svg>

                <div class="cursor-pointer" (click)="viewPlans()">
                  View Plans
                </div>
              </div>
            </span>
            <!--Empty Cart-->
            <ul class="overflow-hidden flex md:gap-24">
              <span *ngIf="hasLocation">
                <li class="flex md:space-x-6">
                  <eds-svg
                    [svgPath]="'assets/icons/shopping-cart.svg'"
                    [attributes]="{ fill: 'currentColor' }"
                    [svgSizeClass]="'icon-28'"
                  ></eds-svg>
                  <div class="cursor-pointer" (click)="cartFunc()">
                    Cart ({{ plansCount }})
                  </div>
                </li>
              </span>
              <li *ngIf="isAbleToSignOut" class="flex md:space-x-4">
                <eds-svg
                  [svgPath]="'assets/icons/individual-base.svg'"
                  [attributes]="{ fill: 'currentColor' }"
                  [svgSizeClass]="'icon-24'"
                ></eds-svg>
                <a (click)="signCustomerOut()" class="hover:cursor-pointer"
                  >Sign Out</a
                >
              </li>
            </ul>
            <ul>
              <li *ngIf="hasLocation" class="flex md:space-x-4">
                <eds-svg
                [svgPath]="'assets/icons/individual-base.svg'"
                [attributes]="{ fill: 'currentColor' }"
                [svgSizeClass]="'icon-24'"
              ></eds-svg>
              <a (click)="signCustomerIn()" class="hover:cursor-pointer">
                 Sign In</a>
              </li>
            </ul>
          </div>
        </nav>

        <!-- Left-Nav Drawer -->
        <nav class="leftNav">
          <eds-drawer
            id="nav-drawer"
            [isDrawerOpen]="isHamburgerMenuOpen"
            [openFrom]="'left'"
            [title]="hamburgerMenuTitle"
            [body]="headerNavDrawerBody"
            (dismiss)="this.isHamburgerMenuOpen = false"
          >
          </eds-drawer>

          
  <ng-template #headerNavDrawerBody>
    <!--First Bordered Button-->
    <div class="container-lg border-b-0 border-t-2 border-blue-dark p-10">
      <button
        class="btn btn-xs btn-flat text-current hover:text-current border-b-2"
        type="button"
        (click)="viewPlans()"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 64 64"
          class="icon-32 inline-flex"
        >
          <path
            fill="#00789E"
            d="M56.712,11.851c-.218-.829-.943-1.425-1.798-1.479C40.673,9.412,33.757,.85,33.693,.766,33.312,.281,32.731,0,32.115,0h-.224c-.614,0-1.194,.283-1.574,.766-.07,.087-6.982,8.649-21.23,9.609-.855,.055-1.578,.651-1.796,1.479-.306,1.115-6.954,27.616,23.354,51.714,.355,.28,.794,.432,1.247,.432h.224c.451,0,.889-.153,1.243-.432C63.665,39.47,57.011,12.969,56.712,11.855"
          />
        </svg>
        <div class="inline-flex sm:ml-10 md:ml-16 ml-14 text-lg">
          {{ firstTitle }}
        </div>
      </button>
    </div>
    <!--BORDERED BUTTON ENDS-->
    <!--SECOND BORDERED BUTTON-->
    <div class="container-lg border-t-2 border-b-2 border-blue-dark p-14">
      <button (click)="cartFunc()">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 64 64"
          fill="#00789E"
          class="icon-32 inline-flex"
        >
          <path
            d="M63.27,15.794c-.696-.981-1.824-1.564-3.027-1.563H17.087l-1.933-5.343v-.085c-.05-.126-.107-.25-.171-.369,0-.099-.099-.199-.156-.298s-.142-.199-.227-.298-.142-.185-.227-.27l-.27-.242-.27-.227-.327-.199-.284-.156-.355-.128-.327-.099H3.716C1.664,6.514,0,8.178,0,10.23s1.664,3.716,3.716,3.716h5.343l9.762,26.999c.552,1.448,1.946,2.4,3.496,2.387h30.352c1.586-.009,2.991-1.025,3.496-2.529l7.645-21.628c.382-1.148,.181-2.41-.54-3.382ZM22.203,46.515c3.029,0,5.485,2.456,5.485,5.485s-2.456,5.485-5.485,5.485-5.485-2.456-5.485-5.485,2.456-5.485,5.485-5.485Zm29.884,0c3.029,0,5.485,2.456,5.485,5.485s-2.456,5.485-5.485,5.485-5.485-2.456-5.485-5.485,2.456-5.485,5.485-5.485Z"
          />
        </svg>
        <div class="inline-flex text-xl sm:ml-8 ml-18 ">
          {{ secondTitle}}
        </div>
      </button>
    </div>
  </ng-template>
        </nav>
      </div>
    </ng-container></header
></ng-container>
