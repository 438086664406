/* eslint-disable @typescript-eslint/member-ordering */
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, AfterViewInit, OnInit } from '@angular/core';
import { Subscription, filter } from 'rxjs';
import { LoaderService } from './services/loader.service';
import { Toast, ToastService } from './services/toast.service';
import { EdsActionButton, EdsIconMeta, EdsNavGroup } from '@electronds/ng-components-duke';
import { Router } from '@angular/router';
import { AppConfig } from './config/app.config';
import { ErrorModalService } from './services/error-modal.service';
import { RemovedPlansModalService } from './services/removed-plans-modal.service';
import { Tier } from './Models/PlansContent';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit, OnDestroy, OnInit {
  @Input() navGroup: EdsNavGroup[] = [];
  @Input() currentNavGroup!: EdsNavGroup;
  public showNavChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  public headerDrawerIsOpen = false;
  public isLoading = false;
  public toast = {} as Toast;
  public showToast = false;
  public hideNav: string = '';
  public showNav = false;
  googleMapsApiKey = ''
  currentUrl = '';

  errorModalMessage: string;
  public isErrorModalOpen = false;
  public isRemovedPlansModalOpen = false;
  removedTiers: Tier[] = [];


  protected subscriptions = new Array<Subscription>();

  public modalIcon: EdsIconMeta = {
    src: '../assets/icons/informational.svg',
    altText: 'warning-icon',
    iconClass: '',
  };

  constructor(
    private _loaderService: LoaderService,
    private _toastService: ToastService,
    private _errorModalService: ErrorModalService,
    private _removedPlansModalService: RemovedPlansModalService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private _config: AppConfig) {
    this.googleMapsApiKey = this._config.getConfig('googleMapsApiKey');
  }

  ngOnInit() {
    this.loadGoogleMaps()
  }

  ngAfterViewInit(): void {
    this.subscriptions.push(
      this._loaderService.showLoader().subscribe((isLoading) => {
        this.isLoading = isLoading;
        this.changeDetectorRef.detectChanges();
      }),

      this._toastService.toast
        .pipe(filter((toast) => toast.message !== ''))
        .subscribe((toast) => this.handleToast(toast)),

      this._errorModalService.$errorMessage.pipe().subscribe((errorMessage) => this.handleErrorModal(errorMessage)),

      this._removedPlansModalService.$removedPlansModal.pipe().subscribe((removedPlans) => this.handleRemovedPlansModal(removedPlans))
    );
  }


  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
    this.subscriptions = new Array<Subscription>();
  }

  public oEconicLogoClick(): void {
    window.location.href = 'https://www.duke-energy.com/home';
  }

  loadGoogleMaps() {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?libraries=places&key=${this.googleMapsApiKey}&loading=async`;
    script.async = true;
    document.head.appendChild(script);
  }

  public handleToast(toast: Toast): void {
    this.toast = { ...toast };
    this.showToast = true;
  }

  public handleErrorModal(errorMessage = undefined): void {
    // make error message undefined if it's empty
    this.errorModalMessage = errorMessage && errorMessage.trim() !== '' ? errorMessage : undefined;
    this.isErrorModalOpen = true;
  }

  public errorModalClosed(): void {
    this.isErrorModalOpen = false;
  }

  public handleRemovedPlansModal(removedPlans: Tier[]): void {
    // make error message undefined if it's empty
    // this.errorModalMessage = errorMessage && errorMessage.trim() !== '' ? errorMessage : undefined;
    this.isRemovedPlansModalOpen = true;
    this.removedTiers = removedPlans;
  }
  
  public removedPlansModalClosed(): void {
    this.isRemovedPlansModalOpen = false;
  }

  public onDukeLogoClick(): void {
    window.location.href = 'https://www.duke-energy.com/home';
  }

  public headerActionButtons: EdsActionButton[] = [
    {
      label: 'Cart',
      icon: {
        path: 'assets/icons/shopping-cart.svg',
        sizeClass: 'icon-28',
        attributes: {
          fill: '#00789e',
        },
      },
    },
    // {
    //   label: 'Sign In',
    //   icon: {
    //     path: 'assets/icons/individual-base.svg',
    //     sizeClass: 'icon-24',
    //     attributes: {
    //       fill: '#00789e',
    //     },
    //   },
    // },
  ];

  public viewPlans(): void {
    this.router.navigate(['/home']);
  }

  public cartFunc(): void {
    this.router.navigate(['/shopping-cart']);
  }
}
