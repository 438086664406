import { Injectable } from '@angular/core';
import { CartSource, ShoppingCartInterface } from './cart.interface';
import { SessionCart } from './session.cart.';
import { SAPCart } from './sap.cart';
import { AWSCart } from './aws.cart';
import { ApiService } from '../api.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Customer, storedUser } from 'src/app/shared/models/customer';
import { CustomerService } from '../customer.service';
import { SourceType } from 'src/app/Models/cart.models';

@Injectable({
  providedIn: 'root'
})
export class CartService  implements ShoppingCartInterface  {
  
  private _current: ShoppingCartInterface;
  private _items = new BehaviorSubject<string[]>([]);

  private get storedUser(): Customer {
      return storedUser();
    }
  
  constructor(private _apiService:ApiService, private _customerService:CustomerService) {
    this.reload();
  }
  
  get source(): CartSource {
    return this._current.source;
  }
 
  get items(): Observable<string[]> {
    // return a locally hosted instance of items 
    // since the cart might change at any time and loose the reference
    /// the carts will be responsible for updating this instance
    return this._items.asObservable();
  }

    // Add a getter to access the current items without subscribing
    get currentItems(): string[] {
      return this._items.getValue();
    }

  reload(): Promise<boolean> {
    this._current = this.determineCartExperience();
    return this._current.reload();
  }

  remove(itemId: string): Promise<boolean> {
    return this._current.remove(itemId);
  }

  clear(): Promise<boolean> {
    return this._current.clear();
  }

  add(item: string): Promise<boolean> {
    return this._current.add(item);
  }

  replace(itemToRemove: string, newItem: string): Promise<boolean> {
    return this._current.replace(itemToRemove, newItem);
  }

  isEmpty(): boolean {
    return this._current.isEmpty();
  }

  hasPlan(id: string): boolean {
    return this._current.hasPlan(id);
  }
  
  private get didAcceptConsent(): boolean {
    return this.storedUser?.state.acceptedTransfer;
  }

  private shouldUseSAPCart(model:Customer):boolean {
     if(model.state.sourceId == SourceType.MyAccount && model.validSrting(model.business_partner_id) && this.didAcceptConsent) {
       return true;
     }

     if(model.state.sourceId == SourceType.DEONE && model.validSrting(model.business_partner_id)) {
      return true;
    }
     return false;
  }


   private determineCartExperience():ShoppingCartInterface {
      const model:Customer = this.storedUser;
      let experience:ShoppingCartInterface = new SessionCart(); // default cart experience is local session storage

      if(!model.shouldUseSessionCart) {
        // when the user is logged-in use either SAP or AWS cart
        const isSapCart:boolean = this.shouldUseSAPCart(model);
        experience =   isSapCart ? new SAPCart(this._apiService) : new AWSCart(this._apiService, model.email);
      }

      experience.items.subscribe(products => {
         // sign up to receive updates from cart 
         // whichever it might be and forward to this instance
         this._items.next(products);
      });

      console.log('cart experience is: ', experience)
  
      return experience;
    }
}
