import { SourceType } from 'src/app/Models/cart.models';

export enum FamilyType {
  SINGLE_FAMILY = 'SINGLE',
  MULTI_FAMILY = 'MULTI',
  MOBILE = 'MOBILE'
}

export enum ResidenceClasification {
  RESIDENTIAL = 'RESI',
  NONE_RESIDENTIAL = 'NONRESI'
}

export enum HouseType {
  OWN = 'OWN',
  RENT = 'RENT'
}

export interface Profile {
  custType:ResidenceClasification;
  houseType: HouseType;
  propertyType:FamilyType;
}

export interface Property {
  address: Address;
  profile: Profile;
}


export interface Address {
  addressNumber: string;
  city: string;
  houseNumber: string;
  state: string;
  street: string;
  zip: string;
}

export interface User {
  state?: AWSState;
  properties: Property[];
  business_partner_id: string;
  email: string;
  firstName: string;
  lastName: string;
}


export interface AWSUpdateCartRequest {
  business_partner_id: string;
  products: string[];
}

export interface AWSState {
  business_partner_id: string;
  sourceId: SourceType;
  acceptedTransfer?:boolean;
}

export interface AWSCartItems {
  business_partner_id: string;
  products: string[];
}

export class Customer implements User {
  state?: AWSState;
  properties: Property[];
  business_partner_id: string;
  email: string;
  apartmentNumber: number;

  /// properties in differnt local flows
  externalId:string;
  expirationDate:string;
  addressNumber:string
  phone: Phone;
  firstName: string;
  lastName: string;
  password: string;
  registration: string;
  fullyAuthenticated: boolean;
  apartment:number;

  selected:number = 0;

  constructor() {
    this.state = {
      business_partner_id: '',
      sourceId: SourceType.DEONE
    };
    this.properties = [
      {
        address: {
          addressNumber: '',
          city: '',
          houseNumber: '',
          state: '',
          street: '',
          zip: ''
        },
        profile: {
          custType: ResidenceClasification.NONE_RESIDENTIAL,
          houseType: HouseType.RENT,
          propertyType: FamilyType.SINGLE_FAMILY
        }
      }
    ];
    this.business_partner_id = '';
    this.email = '';
    this.firstName = '';
    this.lastName = '';
    this.phone = new Phone();
    this.fullyAuthenticated = false;
    this.apartment =  null;
  }

  public updateFromUser(user:User) {
    this.state = user.state;
    this.properties = user.properties;
    this.business_partner_id = user.business_partner_id;
    this.email = user.email;
    this.firstName = user.firstName;
    this.lastName = user.lastName;
  }


  public updateFromCustomer(user:Customer) {
    this.state = user.state;
    this.properties = user.properties;
    this.business_partner_id = user.business_partner_id;
    this.email = user.email;
    this.firstName = user.firstName;
    this.lastName = user.lastName;

    this.externalId = user.externalId;
    this.expirationDate = user.expirationDate;
    this.addressNumber = user.addressNumber;
    this.phone = user.phone;
    this.password = user.password;
    this.registration = user.registration;
    this.fullyAuthenticated = user.fullyAuthenticated;
    this.apartment = user.apartment;
  }

  public get isOwner():boolean { 
    return this.property.profile.houseType == HouseType.OWN;
  }

  public get isLoggedIn():boolean {
    return this.validSrting(this.business_partner_id) && this.validSrting(this.email);
  }

  public replaceCurrentAddress(address:Address):void {
    this.properties[this.selected].address = address;
  }

  public replaceCurrentProfile(profile:Profile):void {
    this.properties[this.selected].profile = profile;
  }

  public isAbleToSignOut(): boolean {
    // could probably optimize this but easier to follow I think this way
    if (this.state.sourceId == SourceType.DEONE) {
      return this.state.acceptedTransfer && this.isLoggedIn
    } else if (this.state.sourceId == SourceType.MyAccount) {
      return this.state.acceptedTransfer;
    } else {
      return false;
    }
  }

  public isAbleToSignIn(): boolean {
    return this.isLoggedIn
  }

  public get property():Property {
    return this.properties[this.selected];
  }
  
  public get address():Address {
    return this.property.address;
  }

  public get profile():Profile {
    return this.property.profile;
  }



  public prettyAddress(): string {
    // TODO: fix issue where house number is duplicated
    const address =  this.address.houseNumber + ' ' + this.address.street + ' ' + this.address.street + ', ' + this.address.state + ' ' + this.address.zip;
    return address.includes('undefined') ? undefined : address
  }

  public get hasConsented():boolean {
    const isLogged  = this.isLoggedIn;
    const consented = (this.state.acceptedTransfer != undefined && this.state.acceptedTransfer);
    return (isLogged && consented);
  }

        /// add the matching profiles based on customer answers
  public get customerProfiles():string[] {
       const list:string[] = [];
       if(this.isOwner) {
         // Eligible_Landlord -> pass if the user marks they're owner
         list.push('Eligible_Landlord');
       }

       const profile:Profile = this.property.profile;
       if(profile.custType == ResidenceClasification.RESIDENTIAL) {
         // Eligible_Residential -> pass if the user marks they're in a residential property
         list.push('Eligible_Residential');
       }

       if(profile.propertyType  == FamilyType.SINGLE_FAMILY) {
         // Eligible_SingleFamily -> pass if the user marks they're in a single family home
         list.push('Eligible_SingleFamily');
       }

       if(profile.propertyType  == FamilyType.MULTI_FAMILY) {
        // Eligibile_MultiFamily -> pass if the user marks they're in in a multi-family home
         list.push('Eligibile_MultiFamily');
       }

       if(this.profile.propertyType  == FamilyType.MOBILE) { 
        // Eligible_MobileHome -> pass if the user marks they're in a mobile home
         list.push('Eligible_MobileHome');
       }

       return list
    }
    public validSrting(value:string):boolean {
      return value != undefined && value.length > 0;
    }

    public validNumber(value:number):boolean {
      return value != undefined && value != -1;
    }



    public hasValidLocation(): boolean {
      const current:Address = this.address;
      if(current == undefined) {
        return false;
      }

      const houseOrAptNumber = this.validSrting(current.houseNumber) || this.validNumber(this.apartment);
      const isValidAddress:boolean = (
                                   houseOrAptNumber &&
                                  this.validSrting(current.zip) &&
                                  this.validSrting(current.city) &&
                                  this.validSrting(current.state))
 
      return isValidAddress;
   }

  public get isMissingbusiness_partner_id():boolean {  
    return !this.validSrting(this.business_partner_id);
  }

  public get shouldUseSessionCart():boolean {
    return !this.validSrting(this.business_partner_id) && !this.validSrting(this.email) && !this.fullyAuthenticated;
  }
}

export class Phone {
  number: string;
  type: string;

  constructor(number: string = undefined, type: string = '') {
    this.number = number;
    this.type = type;
  }
}

export function storedUser(): Customer {
  const raw = sessionStorage.getItem('customer');
  if (raw) {
    const parsed:Customer = JSON.parse(raw);
    let customer: Customer = new Customer();
    customer.updateFromCustomer(parsed);
    return customer;
  }
  return new Customer();
}
