import {HttpClient, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from '../config/app.config';
import { Observable, firstValueFrom, map } from 'rxjs';
import { AWSCartItems, AWSState, AWSUpdateCartRequest, Customer, FamilyType, ResidenceClasification, User } from '../shared/models/customer';
import { TermsRequest, TermsResponse } from '../Models/terms.models';
import { EnrollmentRequest, EnrollmentResponse } from '../Models/enrollment.models';
import { SAPCartTransferRequest, SAPCartItemRequest, SAPCartItemResponse, CartTransferResponse } from '../Models/cart.models';
import { AddressValidationRequest, AddressValidationResponse } from '../pages/location-check/location-models';
import { EligibilityRequest } from '../pages/home/home-models';
import { EligiblityResponse } from '../Models/HomeContent';
import { PlanDetailsRequest } from '../pages/tier/plan-details-models';
import { OrderSummary } from '../pages/profile/order-summary/order-summary.module';
import { ProfileCreationResponse, ProfileTransferRequest, UserCreationRequest } from '../pages/profile/profile.models';
import { Plan } from '../Models/PlansContent';

const APPLICATION_JSON = 'application/json';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  public  _apiBaseUrl = '';

  constructor(
    public  _httpClient: HttpClient,
    public  _config: AppConfig) {
      this._apiBaseUrl = this._config.getConfig('apiBaseUrl');
   }

  // A helper function that returns a generic promise or undefined if the promise is rejected
  public   async wrapped<T>(caller: Observable<T>): Promise<T | undefined> {
   try {
      const response = await firstValueFrom(caller);
      return response as T
   } catch (error) {
     console.error(error);
     return new Promise<T | undefined>((resolve) => resolve(undefined));
  }
 }

   public getNewToken(): Observable<any> {
    const headers = { 'Content-Type': APPLICATION_JSON }
    return this._httpClient.get<any>(`${this._apiBaseUrl}auth/token`,{headers})
    .pipe(map((response:any) => {
      const token = response?.token;
      const refreshToken = response?.refresh_token;
      sessionStorage.setItem('session_access_token',token)
      sessionStorage.setItem('session_refresh_token',refreshToken)
      return response;
    }))
  }
  public fetchRefreshToken(): Observable<any> {
    return this._httpClient.get<any>(`${this._apiBaseUrl}auth/refresh-token`, )
      .pipe(map((response:any) => {
        const token = response?.token;
        const refreshToken = response?.refresh_token;
        sessionStorage.setItem('session_access_token',token)
        sessionStorage.setItem('session_refresh_token',refreshToken)
        return response;
      }))
  }
   public signInCustomer(username: string, password: string): Observable<User> {
    const headers = { 'Content-Type': APPLICATION_JSON }
    const body = {
      u: username,
      p: password
    };

    return this._httpClient.post<any>(`${this._apiBaseUrl}auth/sign-in`, body, {headers})
    .pipe(map((response) => {
      const updatedToken = response?.updatedToken;
      const refreshToken = response?.refresh_token;
      sessionStorage.setItem('session_access_token',updatedToken)
      sessionStorage.setItem('session_refresh_token',refreshToken)
      return response as User
    }));
   }

   public recordTransferProfileResponse(request: ProfileTransferRequest): Observable<string> {
    const headers = { 'Content-Type': APPLICATION_JSON }
    return this._httpClient.post<any>(`${this._apiBaseUrl}customer/transfer-profile`, request, {headers})
    .pipe(map((response) => {
      return response as string
    }));
   }

   public createProfile(customer: Customer): Observable<ProfileCreationResponse> {
    const headers = { 'Content-Type': APPLICATION_JSON }
    const request:UserCreationRequest  = this.buildUserCreationRequest(customer);
    return this._httpClient.post<ProfileCreationResponse>(`${this._apiBaseUrl}customer/create-profile`, request, {headers})
    .pipe(map((response) => {
      return response as ProfileCreationResponse
    }));
   }


   public isRegistered(email: string): Observable<string> {
    const headers = { 'Content-Type': APPLICATION_JSON }
    const body = { email: email };
    return this._httpClient.post<any>(`${this._apiBaseUrl}customer/verify-registered-email`, body, {headers})
    .pipe(map((response) => {
      return response as string
    }));
   }
   public sendValidationEmail(email: string): Observable<string> {
    const headers = { 'Content-Type': APPLICATION_JSON }
    const body = { email: email };
    return this._httpClient.post<any>(`${this._apiBaseUrl}customer/send-validation-email`, body, {headers})
    .pipe(map((response) => {
      return response as string
    }));
   }

   public updateTermsAndConditions(request: TermsRequest): Observable<string> {
    const headers = { 'Content-Type': APPLICATION_JSON }
    return this._httpClient.post<any>(`${this._apiBaseUrl}customer/terms`, request, {headers})
    .pipe(map((response) => {
      return response as string
    }));
   }

   public getTermsAndConditions(): Observable<TermsResponse> {
    const headers = { 'Content-Type': APPLICATION_JSON }
    const options = { headers: headers };
    return this._httpClient.get<any>(`${this._apiBaseUrl}customer/terms`, options)
    .pipe(map((response) => {
      return response as TermsResponse
    }));
   }

   public updateEnrollment(request: EnrollmentRequest): Observable<string> {
    const headers = { 'Content-Type': APPLICATION_JSON }
    return this._httpClient.post<any>(`${this._apiBaseUrl}customer/enrollment`, request, {headers})
    .pipe(map((response) => {
      return response as string
    }));
   }

   public getEnrollment(): Observable<EnrollmentResponse> {
    const headers = { 'Content-Type': APPLICATION_JSON }
    const options = { headers: headers };
    return this._httpClient.get<any>(`${this._apiBaseUrl}customer/enrollment`, options)
    .pipe(map((response) => {
      return response as EnrollmentResponse
    }));
   }

   public _getAWSState(email: string): Observable<AWSState> {
    const params = new HttpParams().set('email', email);
    const options = { params: params };
    return this._httpClient.get<any>(`${this._apiBaseUrl}/shopping-cart/aws/get-cart`, options)
    .pipe(map((response) => {
      return response as AWSState;
    }));
   }

   public _createAWSState(state: AWSState): Observable<string> {
    const headers = { 'Content-Type': APPLICATION_JSON }
    return this._httpClient.post<string>(`${this._apiBaseUrl}customer/state`, state, {headers})
    .pipe(map((response) => {
      return response as string
    }));
   }


   public _getAWSCart(): Observable<AWSCartItems> {
    return this._httpClient.get<AWSCartItems>(`${this._apiBaseUrl}/shopping-cart/aws/get-cart`)
    .pipe(map((response) => {
      return response as AWSCartItems;
    }));
   }

   public _removeAWSCartitem(request: AWSUpdateCartRequest): Observable<string> {
    return this._httpClient.post<string>(`${this._apiBaseUrl}/shopping-cart/aws/remove-item`, request)
    .pipe(map((response) => {
      return response as string;
    }));
   }

   public _addAWSCartItem(request: AWSUpdateCartRequest): Observable<string> {
    return this._httpClient.post<string>(`${this._apiBaseUrl}/shopping-cart/aws/add-item`, request)
    .pipe(map((response) => {
      return response as string;
    }));
   }


   public _clearAWSCart(business_partner_id: string): Observable<string> {
    const body = { business_partner_id: business_partner_id };
    return this._httpClient.post<string>(`${this._apiBaseUrl}/shopping-cart/aws/remove-all`, body)
    .pipe(map((response) => {
      return response as string;
    }));
   }


    /// async wrapper to _getAWSCart
public async getAWSState(email:string):Promise<AWSState> {
  return this.wrapped(this._getAWSState(email));
}

public async createAWSState(state:AWSState):Promise<string> {
  return this.wrapped(this._createAWSState(state));
}

  /// async wrapper to _getAWSCart
public async getAWSCart():Promise<AWSCartItems> {
      return this.wrapped(this._getAWSCart());
}

  /// async wrapper to _clearAWSCart
  public async clearAWSCart(business_partner_id:string):Promise<string> {
    return this.wrapped(this._clearAWSCart(business_partner_id));
}

  /// async wrapper to _removeAWSCartitem
  public async removeAWSCartItem(request:AWSUpdateCartRequest):Promise<string> {
    return this.wrapped(this._removeAWSCartitem(request));
}

  /// async wrapper to _addAWSCartItem
  public async addAWSCartItem(request:AWSUpdateCartRequest):Promise<string> {
    return this.wrapped(this._addAWSCartItem(request));
}
 

   public transferAWSCartToSAP(items:AWSCartItems, addressNumber:string): Observable<CartTransferResponse> {
    const request:SAPCartTransferRequest = {
      address_number: addressNumber,
      cart: items.products
    }

    const headers = { 'Content-Type': APPLICATION_JSON }
    return this._httpClient.post<CartTransferResponse>(`${this._apiBaseUrl}shopping-cart/transfer`, request, {headers})
    .pipe(map((response) => {
      return response as CartTransferResponse
    }));
   }

   /// async wrapper for transferAWSCartToSAP
   public async awsCartToSAPTransfer(items:AWSCartItems, addAddressNumber:string): Promise<CartTransferResponse> {
    return this.wrapped(this.transferAWSCartToSAP(items, addAddressNumber));
   }

   public addSAPCartItem(request:SAPCartItemRequest): Promise<SAPCartItemResponse> {
    return this.wrapped(this._addSAPCartItem(request));
   }
   
   public _addSAPCartItem(request:SAPCartItemRequest): Observable<SAPCartItemResponse> {
    const headers = { 'Content-Type': APPLICATION_JSON }
    return this._httpClient.post<SAPCartItemResponse>(`${this._apiBaseUrl}shopping-cart/sap/add-item`, request, {headers})
    .pipe(map((response) => {
      return response as SAPCartItemResponse
    }));
   }



   public _clearSAPCart(request:SAPCartItemRequest): Observable<string> {
    const headers = { 'Content-Type': APPLICATION_JSON }
    return this._httpClient.post<string>(`${this._apiBaseUrl}shopping-cart/sap/remove-all`, request, {headers})
    .pipe(map((response) => {
      return response as string
    }));
   }

     /// async wrapper to _getSAPCart
  public async clearSAPCart(request:SAPCartItemRequest):Promise<string> {
    return this.wrapped(this._clearSAPCart(request));
 }


   public removeSAPCartItem(request:SAPCartItemRequest): Observable<string> {
    const headers = { 'Content-Type': APPLICATION_JSON }
    return this._httpClient.post<string>(`${this._apiBaseUrl}shopping-cart/sap/remove-item`, request, {headers})
    .pipe(map((response) => {
      return response as string
    }));
   }
   
  /// async wrapper to _getSAPCart
  public async getSAPCart():Promise<OrderSummary> {
    return this.wrapped(this._getSAPCart());
 }

  public _getSAPCart(): Observable<OrderSummary> {
    return this._httpClient.get(`${this._apiBaseUrl}shopping-cart/sap/get-cart`).pipe(
      map((response) => {
        return response as OrderSummary
      })
    )
  }
  

   public fetchSpeedPayRedirect(businessPartnerId: string): Observable<any> {
     const params = new HttpParams().set('business-partner-id', businessPartnerId);
     const options = { params: params};
     return this._httpClient.get(`${this._apiBaseUrl}speed-pay/redirect`,options).pipe(
       map((response) => {
         return response
       })
     )
   }
   public fetchPlanTiers(request:PlanDetailsRequest): Observable<any> {
    const headers = { 'Content-Type': APPLICATION_JSON }
     return this._httpClient.post(`${this._apiBaseUrl}customer/product-details`,request, {headers}).pipe(
       map((response) => {
         return response
       })
     )
   }


   public _fetchPproductDetails(request:PlanDetailsRequest): Observable<Plan> {
    const headers = { 'Content-Type': APPLICATION_JSON }
     return this._httpClient.post<Plan>(`${this._apiBaseUrl}customer/product-details`,request, {headers}).pipe(
       map((response) => {
         return response
       })
     )
   }

   public async fetchPproductDetails(request:PlanDetailsRequest):Promise<Plan> {
    return this.wrapped(this._fetchPproductDetails(request));
   }

   public validateServiceAddress(request: AddressValidationRequest): Observable<AddressValidationResponse> {
    const headers = { 'Content-Type': APPLICATION_JSON }
    return this._httpClient.post<any>(`${this._apiBaseUrl}customer/validate-address`, request, {headers})
    .pipe(map((response) => {
      return response as AddressValidationResponse
    }));
   }

  getPlans(request:EligibilityRequest): Observable<EligiblityResponse> {
    const headers = { 'Content-Type': APPLICATION_JSON }
    return this._httpClient.post<EligiblityResponse>(`${this._apiBaseUrl}customer/eligibility`, request, {headers})
    .pipe(map((response) => {
      return response as EligiblityResponse
    }));
  }

  public async fetchPlans(request:EligibilityRequest):Promise<EligiblityResponse> {
    return this.wrapped(this.getPlans(request));
   }

public buildUserCreationRequest(customer: Customer): UserCreationRequest { 
return {
  profile: {
    email: customer.email,
    password: customer.password,
    firstName: customer.firstName,
    lastName: customer.lastName,
    phoneNumber: customer.phone.number,
    phoneType: customer.phone.type,
    propertyType:  customer.profile.custType,
    familyType:  customer.profile.propertyType,
    type: customer.profile.houseType
  }, 
  address: {
    street_address: customer.address.street,
    city: customer.address.city,
    state: customer.address.state,
    zip_code: `${customer.address.zip}`,
    house_number:  `${customer.address.houseNumber}`,
    apartment: `${customer.apartment}`,
  }
};
}
}
