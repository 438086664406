/* eslint-disable @typescript-eslint/member-ordering */
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import { EdsButtonComponent, EdsDrawerComponent, EdsHorizontalDividerComponent, EdsSvgComponent } from '@electronds/ng-components-duke';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { TabDropdownComponent } from '../tab-dropdown/tab-dropdown.component';
import { CustomerService } from 'src/app/services/customer.service';
import { FormsModule } from '@angular/forms';
import { Plan } from 'src/app/Models/PlansContent';
import { PlanTile } from 'src/app/Models/HomeContent';
import { Customer } from 'src/app/shared/models/customer';
import { CacheService } from 'src/app/services/cache.service';


export interface EdsNavGroup {
  label: string;
  icon: CustomSvg;
  children: EdsNavLink[][];
  expanded?: boolean;
  url?: string
}

export interface EdsNavLink {
  label: string;
  url: string;
}

export interface EdsActionButton {
  icon: CustomSvg;
  label: string;
  action?: (actionLabel?: string) => void;
}

export interface CustomSvg {
  path: string;
  attributes: EdsSvgAttributes;
  sizeClass: string;
}

export interface EdsSvgAttributes {
  [attribute: string]: string;
}

@Component({
  selector: 'app-one-header',
  standalone: true,
  imports: [EdsButtonComponent, EdsSvgComponent, EdsDrawerComponent, EdsHorizontalDividerComponent, RouterModule, NgClass, NgIf, NgFor, TabDropdownComponent, FormsModule],
  templateUrl: './one-header.component.html',
  styleUrls: ['./one-header.component.scss']
})

export class OneHeaderComponent<T> implements OnInit, OnDestroy {
  @Input() isHamburgerMenuOpen = false;
  @Input() navGroups: EdsNavGroup[] = [];
  @Input() currentNavGroup!: EdsNavGroup;
  @Input() navDrawerBody: TemplateRef<T> | undefined = undefined;
  @Input() hamburgerMenuTitle = 'Navigation';
  @Input() allowScrollAnimation: boolean = true;
  @Input() actionButtons: EdsActionButton[] = [];
  @Input() headerIcon?: CustomSvg;
  @Input() plan: Plan;
  @Output() actionButtonPress = new EventEmitter<EdsActionButton>();
  public siteTitle = 'Home Protection Plans';
  public selectedItem = 0;
  public customer: Customer;
  public plansCount: number = 0;
  public plans: PlanTile[] = [];

  protected _onDestroy = new Subject<void>();
  protected justScrolledDown = false;
  protected hasScrolledDown = false;
  protected lastScrollYPos: number | undefined;
  // public isUserFullyAuthenticated = false
  public isAbleToSignOut = false
  public hasLocation: boolean = false
  private _planSubscription: Subscription;
  public headerNavGroups: EdsNavGroup[] = [];


  constructor(
    private _customerService: CustomerService,
    private route: ActivatedRoute,
    private router: Router,
    private _cache: CacheService,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this._customerService.customer.pipe(takeUntil(this._onDestroy)).subscribe(cust => {
      this.customer = cust;
      this.hasLocation = (cust != undefined && cust.hasValidLocation());
      // this.isUserFullyAuthenticated = (cust != undefined && cust.isLoggedIn());
      this.isAbleToSignOut = this.customer.isAbleToSignOut();
      this.plansCount = cust?.addedPlans?.length;
    });

    this._planSubscription = this._cache.plans.subscribe({
      next: (data) => {
        this.plans = data;
        this.showPlans();
        this.cd.detectChanges();
      }
    });
  }


  ngOnDestroy(): void {
    this._onDestroy.next();
    if (this._planSubscription) {
      this._planSubscription.unsubscribe();
    }
  }

  public toggleHamburgerMenuOpen(): void {
    this.isHamburgerMenuOpen = !this.isHamburgerMenuOpen;
  }

  public toggleNavGroup(navGroup: EdsNavGroup): void {
    const wasHiddenBefore = !navGroup.expanded;
    for (const group of this.navGroups) {
      group.expanded = false;
    }
    if (wasHiddenBefore) {
      navGroup.expanded = true;
    }
  }


  public actionButtonPressed(actionButton: EdsActionButton): void {
    if (actionButton.action) {
      actionButton.action(actionButton.label);
    }
    this.actionButtonPress.emit(actionButton);
  }

  public cartFunc(): void {
   this.router.navigate(['/shopping-cart']);
}


  showPlans() {
    const edsNavLinks: EdsNavLink[] = this.plans?.map(tile => ({
      label: tile.name,
      url: tile.id
    }));

    this.headerNavGroups = [
      {
        label: 'All Plans',
        icon: {
          path: 'assets/icons/shield-base.svg',
          sizeClass: 'icon-24',
          attributes: {
            fill: '#00789e'
          },
        },
        children: [edsNavLinks],
      }
    ];
  }

  signCustomerOut(): void {
    this._customerService.resetCustomer();
    this.router.navigate(['/location-check']);
  }
}
