import { AfterViewInit, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, take } from 'rxjs';
import { CartTransferResponse, CartTrasnferRejectedPayload, SAPCartItemRequest, SourceType } from 'src/app/Models/cart.models';
import { ApiService } from 'src/app/services/api.service';
import { CacheService } from 'src/app/services/cache.service';
import { CustomerService } from 'src/app/services/customer.service';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';
import { LoaderService } from 'src/app/services/loader.service';
import { CartService } from 'src/app/services/shopping-cart/cart.service';
import { ToastService } from 'src/app/services/toast.service';
import { AWSCartItems, AWSState, Customer, storedUser, User } from 'src/app/shared/models/customer';
import { SharedModule } from 'src/app/shared/shared.module';
import { OrderSummary } from '../../profile/order-summary/order-summary.module';
import { EligiblityResponse } from 'src/app/Models/HomeContent';
import { Plan, Tier } from 'src/app/Models/PlansContent';
import { PlanDetailsRequest } from '../../tier/plan-details-models';
import { RemovedPlansModalService } from 'src/app/services/removed-plans-modal.service';

@Component({
  selector: 'app-sign-in',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './sign-in.component.html',
  styleUrl: './sign-in.component.scss'
})

export class SignInComponent implements OnInit, OnDestroy, AfterViewInit {
  isFormValid: boolean;
  passwordValue: string;
  emailValue: string;
  customer: Customer;
  plans: string[] = [];
  protected _onDestroy = new Subject<void>();


  constructor(
    private _toastService: ToastService,
    private _router: Router,
    private _apiService: ApiService,
    private _googleAnalyticsService: GoogleAnalyticsService,
    private _customerService: CustomerService,
    private _loaderService: LoaderService,
    private _removedPlansModalService: RemovedPlansModalService,
    private _cache: CacheService,
    private _cart: CartService,
  ) { }

  @HostListener('document:keypress', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key == 'Enter') {
      this.performSignIn();
    }
  }

  ngOnInit(): void {
    this._customerService.customer.pipe(take(1)).subscribe(customer => {
      this.customer = customer;
    });

    this._cart.items.pipe(take(1)).subscribe(items => {
      this.plans = items;
    });


    this.emailValue = this.customer?.email;
  }

  public performSignIn(): void {
    if (this.isFormValid === true) {
      this._loaderService.requestShowLoader();
      this.checkRegistration();
    } else {
      this._loaderService.requestHideLoader();
      this._googleAnalyticsService.sendVPV('sign-in/error/email-or-password-are-incorrect');
      this._toastService.triggerErrorToast('Please check email or password');
    }
  }

  // before the user signs in - lets make sure their email is registered
  public checkRegistration(): void {
    this._loaderService.requestShowLoader();
    const currentEmail: string = this.emailValue;

    this._apiService.isRegistered(currentEmail).pipe(take(1)).subscribe({
      next: resp => {
        this._customerService.updateRegistrationStatus(resp);
        if (resp == 'NotRegistered') {
          this._customerService.updateEmail(currentEmail);
          this._loaderService.requestHideLoader();
          this._router.navigate(['checkout/create-profile']);
        }
        else if (resp == 'Registered') {
          this._customerService.updateEmail(currentEmail);
          this.actualSignIn();
        }
        else if (resp == 'Pending') {
          this._customerService.updateEmail(currentEmail);
          this._loaderService.requestHideLoader();
          this._router.navigate(['checkout/verify-email']);
        }
        else {
          this._loaderService.requestHideLoader();
          this._toastService.triggerErrorToast('Error verifying the provided email.');
        }
      },
      error: error => {
        this._loaderService.requestHideLoader();
        this._toastService.triggerErrorToast('Network Error via Sign In. Please try again.');
      },
    });
  }

  actualSignIn(): void {
    this._apiService.signInCustomer(this.emailValue, this.passwordValue).pipe(take(1)).subscribe({
      next: userResponse => {
        let customer: Customer = storedUser();
        customer.fullyAuthenticated = true;
        this._customerService.updateCustomer(customer);
        customer = this._customerService.updateFromUser(userResponse);
        this.customer = customer;

        if (customer.state.sourceId == SourceType.MyAccount && !customer.state.acceptedTransfer) {
          /// redirect to consent page to generate the state table
          this._loaderService.requestHideLoader();
          this._router.navigate(['checkout/transfer']);

        } else {
          /// user is good to start post login flow
          this.postLoginExperienceFlow(customer);
        }
      },
      error: error => {
        this._loaderService.requestHideLoader();
        this._toastService.triggerErrorToast('Email or password are incorrect');
        console.error('Error signing in', error);
      }
    });
  }

  async clearPreviousSAPCart(user: Customer): Promise<boolean> {
    /// fetch the current SAP cart so we can clear it
    const currentCart: OrderSummary = await this._apiService.getSAPCart();
    if (currentCart != undefined && currentCart.productDetails != undefined && currentCart.productDetails.length > 0) {
      const request: SAPCartItemRequest = { bpId: user.state.business_partner_id, premiseId: user.address.addressNumber, cartId: currentCart.cartId };
      const response = await this._apiService.clearSAPCart(request);
      return response != undefined;
    }

    return true;
  }

  async handleSAPTransfer(user: Customer, cartItems: AWSCartItems): Promise<Customer> {
    if (this.shouldTransferToSAPNow(user.state)) {
      /// Before transfering the cart to SAP. Clear the SAP cart before we add any new items to it
      // this is the sap/remove-all api call
      await this.clearPreviousSAPCart(user);

      // transfer cart to SAP - shopping-cart/transfer
      const response: CartTransferResponse = await this._apiService.awsCartToSAPTransfer(cartItems, user.address.addressNumber);

      /// check that all responses are succesful
      const allSuccess = response.success.length == cartItems.products.length;
      if (response.success.length == 0) {
        console.error('Failed to tranfer cart undefied response');
      }

      if (response == undefined || !allSuccess && response.rejected.length > 0) {
        console.log('list of plans that were rejected by the transfer (and are being removed from the cart from the cart): ', response.rejected);
        // show modal of removed plans
        const removedPlansCodes = response.rejected.map(item => item.code);
        this._removedPlansModalService.handlerTransferErrorResponses(removedPlansCodes);

        // remove items from our cart
        if (removedPlansCodes.length > 0) {
          removedPlansCodes.forEach(item => {
            this._cart.remove(item);
          });
        }
      }
    }

    return user;
  }

  async postLoginExperienceFlow(customer: Customer) {
    /// The user just logged in and may have items in the session cart.
    //  Let's get the session cart so we can merge it with the AWS cart
    const sessionItems = this._cart.currentItems;
    if (sessionItems.length > 0) {
      const reponse = await this._apiService.addAWSCartItem({ business_partner_id: customer.business_partner_id, products: sessionItems });
      console.log(reponse);
    }

    const awsCart: AWSCartItems = await this._apiService.getAWSCart();

    // before determining the cart experience - check if we need to transfer the cart to SAP
    await this.handleSAPTransfer(customer, awsCart); // popup 1

    await this._cart.reload();
    this.customer = customer;

    // grab details of eligible plans
    const plans: EligiblityResponse = await this._cache.getEligiblePlans(customer, this._apiService, this._cart); //popup 2

    // hide the loader
    this._loaderService.requestHideLoader();
    // TODO: check for plans that were removed here as well
    if (plans.products.length == 0) {
      this._router.navigate(['unsupported'], { queryParams: { reason: 'not-eligible' } });
      return;
    }

    this._cart.isEmpty() ? this._router.navigate(['checkout/home']) : this._router.navigate(['/shopping-cart']);
  }


  shouldTransferToSAPNow(state: AWSState): boolean {
    return (
      // case one: customer is a de-one user is always ready to transfer
      (state.sourceId === SourceType.DEONE) ||
      // case two: my-account user who has agreed to transfer his/her profile
      (state.sourceId === SourceType.MyAccount && state.acceptedTransfer)
    );
  }




  ngAfterViewInit(): void {
    this._googleAnalyticsService.sendVPV('sign-in/load/sign-in');
  }

  public updateUserEmail($event): void {
    this.emailValue = $event;
  }

  public updateUserPassword($event): void {
    this.passwordValue = $event;
  }

  public updateFormValidation($event): void {
    this.isFormValid = $event;
  }

  public goBack(): void {
    this._googleAnalyticsService.sendVPV('sign-in/load/sign-in');
    this._router.navigate(['checkout/home']);
  }

  public goto(url: string): void {
    window.open(url, '_blank');
  }

  public register(): void {
    this._googleAnalyticsService.sendVPV('sign-in/clickthrough/sign-in/na/na/register');
    window.open('https://p-auth.duke-energy.com/my-account/registration-r5');
  }

  public needHelpClick() {
    this._googleAnalyticsService.sendVPV('sign-in/clickthrough/na/need-help-signing-in');
    window.open('https://www.duke-energy.com/need-help-sign-in', '_blank');
  }

  public forgotUsernameClick(_) {
    this._googleAnalyticsService.sendVPV('sign-in/clickthrough/sign-in/na/na/forgot-username');
    window.open('https://www.duke-energy.com/forgot-username', '_blank');
  }

  public forgotPasswordClick(_) {
    this._googleAnalyticsService.sendVPV('sign-in/clickthrough/sign-in/na/na/forgot-password');
    window.open('https://p-auth.duke-energy.com/my-account/sign-in/forgot-password', '_blank');
  }

  // TODO: still in need of?
  public eventClick(event) {
    if ((event.target.innerHTML).indexOf('help') >= 0) {
      //  this._googleAnalyticsService.sendVPV('sign-in/clickthrough/na/need-help-signing-in');
    }
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
  }
}
