import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { AppConfig } from './config/app.config';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { OneHeaderComponent } from 'src/app/components/one-header/one-header.component';
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AuthInterceptor } from './services/auth-interceptor.service';
import { AuthResponseInterceptorService } from './services/auth-response-interceptor.service';


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    AppRoutingModule,
    OneHeaderComponent
],
  providers: [ AppConfig, 
    {
      provide: APP_INITIALIZER,
      useFactory: getUseFactory,
      deps: [AppConfig],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      // deps: [TokenService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthResponseInterceptorService,
      // deps: [TokenService],
      multi: true
    },],
  bootstrap: [AppComponent]
})
export class AppModule { }
export function getUseFactory(config: AppConfig) {
  return () => config.load();
}
